import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Form } from '@unform/web'
import {
  LiveSearch,
  Select,
  Input,
  InputMasked
} from '../../components/Formulario'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import FormatDate from '../../components/FormatDate'

export default function List({ session }) {
  const formRef = useRef()
  const [list, setList] = useState(false)
  const [page, setPage] = useState(1)
  const limit = process.env.REACT_APP_PAGINATION_LIMIT
  const [endPage, setEndPage] = useState(false)
  const [loading, setLoading] = useState(false)

  const [filters, setFilters] = useState({
    organization: null,
    api: null,
    endpoint: null,
    status: null,
    origin: null,
    fromDate: null,
    toDate: null
  })
  const [__organization, setOrganization] = useState(null)
  const [__api, setApi] = useState(null)

  const getOrganizations = async search => {
    if (session && session.user?.organization?.admin) {
      const r = await api.get(`/organizacoes/?search=${search}&no-pre-load`)
      return r.data.map(data => ({
        value: data._id,
        label: data.name
      }))
    } else {
      const { organization: org } = session.user
      setFilters(previousFilters => {
        previousFilters.organization = org._id
        return previousFilters
      })
      setOrganization(org._id)
      return [{ value: org._id, label: org.name }]
    }
  }

  const getApis = useCallback(
    async search => {
      if (search && search.length > 0 && search.length < 3) return
      if (!__organization) return
      const r = await api.get(
        `/organizacoes/${__organization}/apis/?search=${search}&limit=${limit}&page=1&no-pre-load`
      )
      return r.data.map(data => ({
        value: data._id,
        label: data.name
      }))
    },
    [__organization, limit]
  )

  const getEndpoints = useCallback(
    async search => {
      if (!__api) return
      const r = await api.get(
        `/apis/${__api}/endpoints/?search=${search}&limit=${limit}&page=1&no-pre-load`
      )
      return r.data.map(data => ({
        value: `[${data.method}] ${data.path}`,
        label: `[${data.method.toUpperCase()}] ${data.path}`
      }))
    },
    [__api, limit]
  )

  const loadList = useCallback(
    async (_filters = formRef.current.getData(), replace = false) => {
      const r = await api.get(
        `/logs?${Object.keys(_filters)
          .map(
            filter =>
              `${filter}=${
                _filters[filter] === null || _filters[filter] === undefined
                  ? ''
                  : encodeURIComponent(_filters[filter])
              }`
          )
          .join('&')}&limit=${limit}&page=${page}`
      )
      if (!r.data || (Array.isArray(r.data) && r.data.length < limit))
        setEndPage(true)
      setList(previousList =>
        replace || page === 1 ? r.data : previousList.concat(r.data)
      )
      setLoading(false)
    },
    [limit, page]
  )

  useEffect(() => {
    getApis()
    getEndpoints()
  }, [getApis, getEndpoints])

  const proccessForm = async data => {
    if (loading) return
    setLoading(true)
    setFilters(data)
    loadList(data, true)
  }

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-file-alt mr-1"></i> Logs
          </>
        }
      />

      <div className="box mt-5">
        <h4 className="title is-4">Filtros</h4>
        <Form ref={formRef} onSubmit={proccessForm} initialData={filters}>
          <div className="columns mb-0">
            <div className="column is-one-quarter py-0">
              <LiveSearch
                name="organization"
                label="Organização"
                placeholder="Todas"
                cacheOptions
                defaultOptions
                loadOptions={getOrganizations}
                backspaceRemovesValue
                escapeClearsValue
                isClearable
                onChange={e => {
                  if (e === null) {
                    setOrganization(null)
                    formRef.current.clearField('api')
                    formRef.current.getFieldRef('api').select.state.value = null
                    setApi(null)
                    formRef.current.clearField('endpoint')
                    formRef.current.getFieldRef(
                      'endpoint'
                    ).select.state.value = null
                  } else {
                    setOrganization(e.value)
                  }
                }}
              />
            </div>
            <div className="column is-one-quarter py-0">
              <LiveSearch
                name="api"
                label="API"
                placeholder="Todas"
                isDisabled={__organization === null}
                loadOptions={getApis}
                backspaceRemovesValue
                escapeClearsValue
                isClearable
                onChange={e => {
                  if (e === null) {
                    formRef.current.clearField('endpoint')
                    formRef.current.getFieldRef(
                      'endpoint'
                    ).select.state.value = null
                    setApi(null)
                  } else {
                    setApi(e.value)
                  }
                }}
              />
            </div>
            <div className="column is-two-quarter py-0">
              <LiveSearch
                name="endpoint"
                label="Endpoint"
                placeholder="Todos"
                isDisabled={__api === null}
                loadOptions={getEndpoints}
                backspaceRemovesValue
                escapeClearsValue
                isClearable
              />
            </div>
          </div>
          <div className="columns mb-0">
            <div className="column is-two-quarter py-0">
              <Input
                name="key"
                label="Chave"
                type="text"
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
              />
            </div>
            <div className="column is-one-quarter py-0">
              <Select name="status" label="Resposta">
                <option value="">Todas</option>
                <option value="200">200 - Ok</option>
                <option value="401">401 - Unauthorized</option>
              </Select>
            </div>
            <div className="column is-one-quarter py-0">
              <Input
                name="origin"
                label="Origem"
                type="text"
                placeholder="https://dominio"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter py-0">
              <InputMasked
                name="fromDate"
                label="Período"
                type="text"
                format="##/##/#### ##:##"
                placeholder="dd/mm/aaaa hh:mm"
                mask={[
                  'd',
                  'd',
                  'm',
                  'm',
                  'a',
                  'a',
                  'a',
                  'a',
                  'h',
                  'h',
                  'm',
                  'm'
                ]}
              />
            </div>
            <div className="column is-one-quarter py-0">
              <InputMasked
                name="toDate"
                label="&nbsp;"
                type="text"
                format="##/##/#### ##:##"
                placeholder="dd/mm/aaaa hh:mm"
                mask={[
                  'd',
                  'd',
                  'm',
                  'm',
                  'a',
                  'a',
                  'a',
                  'a',
                  'h',
                  'h',
                  'm',
                  'm'
                ]}
              />
            </div>
            <div className="column is-one-quarter py-0">
              <Select name="order" label="Ordenação" defaultValue="DESC">
                <option value="ASC">Mais antigos</option>
                <option value="DESC">Mais novos</option>
              </Select>
            </div>
            <div className="column is-one-quarter py-0">
              <div className="field">
                <label className="label">&nbsp;</label>
                <div className="control">
                  <Button
                    type="submit"
                    extraClass="is-fullwidth is-link has-text-dark is-normal"
                    icon={loading ? 'spinner fa-pulse' : 'filter'}
                    disabled={loading}
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>

      <div className="box mt-5">
        {!list && <p>Utilize os filtros acima para definir sua busca.</p>}
        {Array.isArray(list) && (
          <>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th colSpan={2}>Origem</th>
                  <th className="is-narrow">API</th>
                  <th colSpan={3}>Requisição</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && !loading && (
                  <tr>
                    <td colSpan={6}>Nenhum registro encontrado</td>
                  </tr>
                )}
                {loading && (
                  <tr>
                    <td colSpan={6}>
                      <i className="fas fa-spinner fa-pulse mr-1"></i>{' '}
                      Carregando...
                    </td>
                  </tr>
                )}
                {list.length > 0 && !loading && (
                  <>
                    {list.map((registro, key) => (
                      <tr key={key} className="is-size-7">
                        <td className="is-vcentered is-narrow has-text-centered is-size-6">
                          <Button
                            url={`/central/logs/${registro._id}/detalhes`}
                            target="_blank"
                            icon="external-link-alt"
                          />
                        </td>
                        <td className="is-vcentered is-narrow">
                          <p>
                            <i className="has-text-grey-light fas fa-clock mr-1"></i>
                            <FormatDate timestamp={registro.created_in} />
                          </p>
                          {`${registro.request.origin}`}
                          
                        </td>
                        <td className="is-vcentered">
                          {registro.key.api.name}
                        </td>
                        <td className="is-vcentered is-narrow">
                          <span className="tag is-light mr-1">
                            GET
                            {registro.request.method}
                          </span>
                        </td>
                        <td
                          className="is-vcentered"
                          style={{ wordBreak: 'break-all' }}
                        >
                          {registro.key.api.url}
                          {registro.request.endpoint}
                        </td>
                        <td className="is-vcentered is-narrow">
                          <span
                            className={`tag is-${
                              registro.status === 200 ? 'success' : 'danger'
                            }`}
                          >
                            {registro.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {list && !endPage && (
              <Button
                onClick={() => {
                  setPage(page + 1)
                  loadList()
                }}
                icon="sync"
                extraClass="is-dark"
              >
                Carregar mais
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}
