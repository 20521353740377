import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import AdicionarUsuario from './AdicionarUsuario'
import RemoverUsuario from './RemoverUsuario'

export default function ListarUsuarios({
  key_id,
  api_id,
  api_scope,
  api_public,
  disabled
}) {
  const [usuarios, setUsuarios] = useState(false)

  const loadUsuarios = useCallback(async () => {
    const r = await api.get(`/chaves/${key_id}/usuarios`)
    setUsuarios(r.data)
  }, [key_id])

  useEffect(() => {
    loadUsuarios()
  }, [loadUsuarios])

  return (
    <>
      {!disabled && (
        <AdicionarUsuario
          key_id={key_id}
          api_id={api_id}
          api_scope={api_scope}
          api_public={api_public}
          afterCreate={() => loadUsuarios()}
        />
      )}
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th colSpan={disabled ? 1 : 2}>Nome</th>
            <th className="is-narrow">Organização</th>
          </tr>
        </thead>
        <tbody>
          {!usuarios && (
            <tr>
              <td colSpan={disabled ? 2 : 3}>
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {usuarios && (
            <>
              {usuarios.length === 0 && (
                <tr>
                  <td colSpan={disabled ? 2 : 3}>Nenhum usuário adicionado</td>
                </tr>
              )}
              {usuarios.length > 0 && (
                <>
                  {usuarios.map((usuario, key) => (
                    <tr key={key}>
                      {!disabled && (
                        <td className="is-vcentered is-narrow">
                          <RemoverUsuario
                            key_id={key_id}
                            id={usuario._id}
                            afterDelete={() => loadUsuarios()}
                          />
                        </td>
                      )}
                      <td className="is-vcentered">{usuario.name}</td>
                      <td className="is-vcentered">
                        {usuario?.organization
                          ? usuario.organization.name
                          : '--'}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
