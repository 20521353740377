import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonDelete } from '../../../components/ButtonDelete'

export default function ApagarEscopo({ api_id, scope_id, afterDelete }) {
  const [alert, setAlert] = useState()

  async function deleteEscopo() {
    const r = await api.delete(`/apis/${api_id}/escopos/${scope_id}`)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">Organização removida do escopo!</Alert>)
      afterDelete()
    } else {
      setAlert(
        <Alert color="danger">
          Não foi possível remover a Organização do escopo.
          <br />
          Tente novamente ou entre em contato com o suporte da Central do
          Desenvolvedor.
        </Alert>
      )
    }
  }

  return (
    <>
      {alert}
      <ButtonDelete
        onClick={e => {
          e.preventDefault()
          if (
            window.confirm('Tem certeza que deseja remover esta organização?')
          )
            deleteEscopo()
        }}
        title="Remover organização"
      ></ButtonDelete>
    </>
  )
}
