import React, { useEffect, createRef } from 'react'
import { useField } from '@unform/core'

export default function Select({
  name,
  label,
  options,
  children = false,
  required = false,
  size = 'normal',
  mb = 5,
  ...rest
}) {
  const inputRef = createRef()
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue(ref) {
        if (ref.value) {
          return ref.value
        } else {
          return null
        }
      }
    })
  }, [fieldName, inputRef, registerField])

  return (
    <div className={`field mb-${mb}`}>
      {label && (
        <label htmlFor={name} className="label">
          {label}
          {required ? <small className="has-text-grey">*</small> : ''}
        </label>
      )}
      <div className="control">
        <div className={`select is-fullwidth is-${size}`}>
          <select
            id={name}
            name={name}
            ref={inputRef}
            required={required}
            {...rest}
          >
            {children}
            {!children &&
              options.map((opt, i) => {
                return (
                  <option key={i} value={opt.id}>
                    {opt.nome}
                  </option>
                )
              })}
          </select>
        </div>
      </div>
    </div>
  )
}
