import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form } from '@unform/web'
import CPF from 'cpf-check'

import api from '../services/api'
import organograma_api from '../services/organograma_api'
import {
  hasLocalTrabalho,
  hasFNG,
  getUnidadeGestora,
  hasMatriculaAtiva
} from '../helpers/servidor.helper'
import Logo from '../assets/logo-negativa.png'
import LogoGoverno from '../assets/logo-governo.png'
import Alert from '../components/Alert'
import Button from '../components/Button'
import { Input } from '../components/Formulario'

export default function Login() {
  const history = useHistory()
  const [alert, setAlert] = useState(false)

  // Consulta nova organização do usuário
  async function getNewOrganization(unidade) {
    // Consulta se a unidade existe na Central
    return await api
      .get(`/organizacoes/nome/${unidade.sigla}`)
      .then(async response => {
        // Se a unidade existir, retorna seu ID
        // Se não, cria unidade e retorna seu ID
        if (response.status === 200 && response.data) {
          return response.data._id
        } else if (response.status === 200 && response.data === false) {
          return await api
            .post(`/organizacoes`, {
              name: unidade.sigla,
              organograma: await getUnidadeOrganograma(unidade.id)
            })
            .then(res => {
              return res.data._id
            })
        }
      })
  }

  // Consulta detalhes da unidade no Organograma
  async function getUnidadeOrganograma(unidade_id) {
    if (!unidade_id) return {}
    const r = await organograma_api
      .get(`/unidades/${unidade_id}/detalhes`)
      .then(res => res)
      .catch(err => err.response)
    if (r?.status === 200) {
      return r.data
    } else {
      return {}
    }
  }

  // Valida se o usuário mantém seu escopo de organização na Central com base nos seus vínculos ativos disponíveis na API de Servidores
  async function keepOrganization(user) {
    if (!user) return false

    const matriculas = await hasMatriculaAtiva(user.cpf)
    if (!matriculas) return false

    const departamentos = hasLocalTrabalho(matriculas)
    if (!departamentos) return false

    const fng = hasFNG(matriculas)
    if (!fng) return false

    const unidades = await getUnidadeGestora(departamentos)

    const keepScope = unidades.filter(u => u.sigla === user.organization.name)
    if (!keepScope || (Array.isArray(keepScope) && keepScope.length < 1))
      return unidades

    return true
  }

  function setSession(data) {
    const { token } = data
    localStorage.setItem('cdev_session', token)
  }

  // Processa o login do usuário
  async function doLogin(data) {
    const { cpf, senha } = data
    if (!CPF.validate(cpf)) {
      setAlert(
        <Alert color="danger">Preencha um CPF válido para acessar!</Alert>
      )
      return false
    }
    if (!senha || senha.length < 6) {
      setAlert(
        <Alert color="danger">
          Preencha uma senha válida para acessar, as senhas do Meu Acesso do
          Governo tem pelo menos 6 caracteres.
        </Alert>
      )
      return false
    }
    await api
      .post('/login', { cpf: CPF.strip(cpf), senha })
      .then(async r => {
        // Se o usuário não for público
        if (!r.data.user.public) {
          // Valida organização
          const keep = await keepOrganization(r.data.user)
          // Se a validação retornar uma lista, atualiza organização na Central
          // Se não, remove a organização do usuário e o transforma em usuário público
          if (Array.isArray(keep) && keep.length > 0) {
            // Atualiza organização do usuário e remove o status de administrador
            await api.put(`/usuarios/${r.data.user._id}`, {
              admin: false,
              public: false,
              organization: await getNewOrganization(keep[0])
            })
            setAlert(
              <Alert color="warning">
                Detectamos uma mudança no seu vínculo com o Governo e
                atualizamos seu escopo de acesso a Central. Faça o login
                novamente para continuar.
              </Alert>
            )
            return
          } else if (keep === false) {
            // Atualiza organização, status de administrador e status de escopo público do Usuário
            await api.put(`/usuarios/${r.data.user._id}`, {
              admin: false,
              public: true,
              organization: null
            })
            setAlert(
              <Alert color="warning">
                Detectamos uma mudança no seu vínculo com o Governo e
                atualizamos seu escopo de acesso a Central. Faça o login
                novamente para continuar.
              </Alert>
            )
            return
          } else {
            setSession(r.data)
          }
        } else {
          setSession(r.data)
        }
        history.push('/central')
      })
      .catch(err =>
        setAlert(
          <Alert color="danger">{err.response.data.error.message}</Alert>
        )
      )
  }

  return (
    <div id="login" className="columns is-vcentered is-centered">
      {alert}
      <div className="column is-four-fifths is-one-third-table is-one-third-desktop is-one-third-widescreen">
        <div className="p-5 has-text-centered">
          <div>
            <img
              src={LogoGoverno}
              alt="Governo de Rondônia"
              style={{ maxWidth: 200 }}
            />
          </div>
          <img src={Logo} alt="Central do Desenvolvedor" />
        </div>
        <div className="card my-3 has-background-white is-borderless">
          <div className="card-content">
            <div className="content">
              <h4 className="has-text-grey is-uppercase mb-2">Acesse</h4>
              <p className="has-text-black mb-5">
                Faça autenticação com a mesma senha do{' '}
                <a
                  href="https://meuacesso.sistemas.ro.gov.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="has-text-weight-bold"
                >
                  Meu Acesso
                </a>
                , central de acesso do Governo de Rondônia.
              </p>
              <Form onSubmit={doLogin}>
                <Input
                  mb={3}
                  name="cpf"
                  placeholder="CPF"
                  icon="user"
                  size="medium"
                />
                <Input
                  mb={5}
                  name="senha"
                  placeholder="Senha"
                  type="password"
                  icon="lock"
                  size="medium"
                />
                <Button
                  type="submit"
                  extraClass="is-link is-medium is-fullwidth"
                  small={false}
                  icon="sign-in-alt"
                >
                  Entrar
                </Button>
              </Form>
              <p className="has-text-black mt-5">
                Não é cadastrado ainda?{' '}
                <Link to="/cadastre-se" className="has-text-weight-bold">
                  Cadastre-se
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="p-5 has-text-centered"></div>
      </div>
    </div>
  )
}
