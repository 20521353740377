import React, { useState, useEffect, useCallback } from 'react'
import { Redirect } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import { DetailBox } from '../../components/DetailBox'
import ListarAdministradores from '../Organizacao/details/ListarAdministradores'

export default function Details({ session }) {
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const { organization } = session.user

  const loadData = useCallback(async () => {
    const r = await api
      .get(`/organizacoes/${organization._id}`)
      .then(data => data)
      .catch(error => error.response)
    if (r.status !== 200) {
      setError(r.data)
    } else {
      setData(r.data)
    }
  }, [organization._id])

  useEffect(() => {
    loadData()
  }, [loadData])

  return error ? (
    <>
      <Alert color="danger">{error.error.message}</Alert>
      <Redirect to="/central" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-building mr-1"></i> Minha
            Organização
          </>
        }
      >
        <Button url="/central" icon="arrow-left">
          Voltar
        </Button>
        {data && (
          <Button
            url={`/central/minha-organizacao/editar`}
            icon="pen"
            extraClass="is-primary"
          >
            Editar
          </Button>
        )}
      </PageTitle>

      <div className="box mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {data && (
          <>
            <h3 className="title is-3 mb-2">{data.name}</h3>
            {data.admin && (
              <p className="tag is-primary">Administradora da Plataforma</p>
            )}
          </>
        )}
      </div>

      {data && (
        <DetailBox title="Administradores" icon="users" extraClass="mt-5">
          <ListarAdministradores organization_id={data._id} />
        </DetailBox>
      )}
    </>
  )
}
