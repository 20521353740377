import React from 'react'
import { Link } from 'react-router-dom'

import Logo from '../assets/logo-negativa.png'
import LogoGoverno from '../assets/logo-governo.png'

export default function Template({ children, session }) {
  return (
    <>
      <div className="has-background-dark">
        <div className="container">
          <nav
            className="navbar is-dark"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <Link className="navbar-item" to="/">
                <img
                  id="logo-cdev"
                  src={Logo}
                  alt="Central do Desenvolvedor"
                  className="mr-2"
                />
              </Link>
              <img
                id="logo-governo"
                src={LogoGoverno}
                alt="Governo de Rondônia"
                className="mr-2"
              />
            </div>

            <div className="navbar-menu">
              <div className="navbar-end">
                <span className="navbar-item">
                  <i className="fas fa-user-circle fa-lg mr-2"></i>
                  <strong>{session.user.name}</strong>
                  {session.user?.organization ? (
                    <>, {session.user?.organization?.name}</>
                  ) : (
                    ''
                  )}
                </span>
                <div className="navbar-item">
                  <div className="buttons">
                    <Link
                      to="/central/notificacoes"
                      className="button is-small mr-3"
                    >
                      <span className="icon">
                        <i className="fas fa-bell"></i>
                      </span>
                    </Link>
                    <Link to="/logout" className="button is-small is-danger">
                      <span className="icon">
                        <i className="fas fa-sign-out-alt"></i>
                      </span>
                      <span className="is-uppercase has-text-weight-bold">
                        Sair
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <hr className="m-0" />
      <div className="container app">
        <div className="columns m-0">
          <div
            className="column is-narrow-desktop is-narrow pr-6 py-5"
            style={{ borderRight: '1px solid #454545' }}
          >
            <div className="menu">
              <p className="menu-label has-text-grey-light">Geral</p>
              <ul className="menu-list">
                <li>
                  <Link to="/central">
                    <i className="has-text-grey-light fas fa-columns mr-1"></i>{' '}
                    Painel
                  </Link>
                </li>
                <li>
                  <Link to="/central/minhas-chaves">
                    <i className="has-text-grey-light fas fa-key mr-1"></i>{' '}
                    Minhas Chaves
                  </Link>
                </li>
              </ul>
              {session.user.admin && (
                <>
                  <p className="menu-label has-text-grey-light">Organização</p>
                  <ul className="menu-list">
                    <li>
                      <Link to="/central/apis">
                        <i className="has-text-grey-light fas fa-code mr-1"></i>{' '}
                        API's
                      </Link>
                    </li>
                    <li>
                      <Link to="/central/chaves">
                        <i className="has-text-grey-light fas fa-key mr-1"></i>{' '}
                        Chaves
                      </Link>
                    </li>
                    <li>
                      <Link to="/central/moderar">
                        <i className="has-text-grey-light fas fa-tasks mr-1"></i>{' '}
                        Moderação
                      </Link>
                    </li>
                    <li>
                      <Link to="/central/logs">
                        <i className="has-text-grey-light fas fa-file-alt mr-1"></i>{' '}
                        Logs
                      </Link>
                    </li>
                    {session.user.admin && !session.user?.organization.admin && (
                      <li>
                        <Link to="/central/minha-organizacao">
                          <i className="has-text-grey-light fas fa-building mr-1"></i>{' '}
                          Minha Organização
                        </Link>
                      </li>
                    )}
                  </ul>
                  {session.user.admin && session.user?.organization.admin && (
                    <>
                      <p className="menu-label has-text-grey-light">
                        Administrador
                      </p>
                      <ul className="menu-list">
                        <li>
                          <Link to="/central/organizacoes">
                            <i className="has-text-grey-light fas fa-building mr-1"></i>{' '}
                            Organizações
                          </Link>
                        </li>
                        <li>
                          <Link to="/central/usuarios">
                            <i className="has-text-grey-light fas fa-users mr-1"></i>{' '}
                            Usuários
                          </Link>
                        </li>
                      </ul>
                    </>
                  )}
                </>
              )}
              {session.user.public && (
                <>
                  <p className="menu-label has-text-grey-light">Ajuda</p>
                  <ul className="menu-list">
                    <li>
                      <Link to="/central/sou-servidor">
                        <i className="has-text-grey-light fas fa-id-badge mr-1"></i>{' '}
                        Sou Servidor
                      </Link>
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
          <div className="column pl-6 py-5">
            <div>{children}</div>
            <hr />
            <p className="has-text-grey-light m-0">
              &copy; SETIC - Governo de Rondônia
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
