import React, { useCallback, useState } from 'react'
import AsyncSelect from 'react-select/async'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonAdd } from '../../../components/ButtonAdd'

export default function AdicionarAdministrador({
  organization_id,
  afterCreate
}) {
  const [alert, setAlert] = useState()
  const [user, setUser] = useState('')

  const getUsers = useCallback(
    async search => {
      if (search.length > 0 && search.length < 3) return
      const r = await api.get(
        `/organizacoes/${organization_id}/usuarios/${`?search=${search}`}`
      )
      return r.data.map(data => ({
        value: data._id,
        label: data.name
      }))
    },
    [organization_id]
  )

  async function saveUsuario(e) {
    e.preventDefault()
    if (user === '' || !user) {
      setAlert(
        <Alert color="danger">
          É necessário selecionar um usuário para defini-lo como administrador!
        </Alert>
      )
      return
    }
    const button = e.target
    button.disabled = true
    const r = await api
      .put(`/usuarios/${user}`, {
        organization: organization_id,
        admin: true
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Administrador adicionado!</Alert>)
      setUser('')
      afterCreate()
    }
    button.disabled = false
  }

  return (
    <>
      {alert}
      <div className="py-3">
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control">
                <div className="is-fullwidth has-text-dark">
                  <AsyncSelect
                    name="user"
                    id="user-admin"
                    cacheOptions
                    defaultOptions
                    loadOptions={getUsers}
                    onChange={e => setUser(e.value)}
                    placeholder="Selecione..."
                  />
                </div>
              </div>
            </div>
            <ButtonAdd small={false} onClick={e => saveUsuario(e)} />
          </div>
        </div>
      </div>
    </>
  )
}
