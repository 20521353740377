import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import AdicionarPermissao from './AdicionarPermissao'
import RemoverPermissao from './RemoverPermissao'

export default function ListarPermissoes({
  key_id,
  api_id,
  disabled,
  updatePermissions
}) {
  const [permissoes, setPermissoes] = useState(false)

  const loadPermissoes = useCallback(async () => {
    const r = await api.get(`/chaves/${key_id}/permissoes`)
    setPermissoes(r.data)
    if (r.data.length > 0) {
      const approved = r.data.filter(({ approved }) => approved)
      updatePermissions(
        approved ? approved.map(({ permission }) => permission.name) : []
      )
    } else {
      updatePermissions([])
    }
  }, [key_id, updatePermissions])

  useEffect(() => {
    loadPermissoes()
  }, [loadPermissoes])

  return (
    <>
      {!disabled && (
        <AdicionarPermissao
          key_id={key_id}
          api_id={api_id}
          afterCreate={() => loadPermissoes()}
        />
      )}
      <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th colSpan={`${disabled ? 1 : 2}`}>Permissão</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {!permissoes && (
            <tr>
              <td colSpan={disabled ? 2 : 3}>
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {permissoes && (
            <>
              {permissoes.length === 0 && (
                <tr>
                  <td colSpan={disabled ? 2 : 3}>
                    Nenhuma permissão adicionada
                  </td>
                </tr>
              )}
              {permissoes.length > 0 && (
                <>
                  {permissoes.map((permissao, key) => (
                    <tr key={key}>
                      {!disabled && (
                        <td className="is-vcentered is-narrow">
                          <RemoverPermissao
                            key_id={key_id}
                            id={permissao._id}
                            afterDelete={() => loadPermissoes()}
                          />
                        </td>
                      )}
                      <td className="is-vcentered is-narrow">
                        {permissao.permission.name}
                      </td>
                      <td
                        className={`is-vcentered has-text-${
                          permissao.permission.moderate
                            ? permissao.approved
                              ? 'info'
                              : permissao.disapproved
                              ? 'danger'
                              : 'warning'
                            : 'white'
                        }`}
                      >
                        <i
                          className={`fas fa-${
                            permissao.permission.moderate
                              ? permissao.approved
                                ? 'check-square'
                                : permissao.disapproved
                                ? 'ban'
                                : 'clock'
                              : 'check'
                          } mr-1`}
                        ></i>{' '}
                        {permissao.permission.moderate
                          ? permissao.approved
                            ? 'Aprovada'
                            : permissao.disapproved
                            ? 'Reprovada: ' + permissao.reason
                            : 'Aguardando moderação'
                          : 'Liberada'}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
