import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

api.interceptors.request.use(function (config) {
  config.headers.Authorization = process.env.REACT_APP_API_KEY
  return config
})

api.interceptors.request.use(
  function (config) {
    if (config.url.indexOf('no-pre-load') < 0) {
      window.$loading += 1
      document.getElementById('loading').setAttribute('rel', window.$loading)
    }
    return { ...config }
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (response) {
    if (response.config.url.indexOf('no-pre-load') < 0) {
      window.$loading -= 1
      document.getElementById('loading').setAttribute('rel', window.$loading)
    }
    return response
  },
  function (error) {
    document.getElementById('loading').setAttribute('rel', 0)
    return Promise.reject(error)
  }
)

export default api
