import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from '@unform/web'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import { Input, Checkbox, Submit } from '../../components/Formulario'
import Alert from '../../components/Alert'

export default function New() {
  const history = useHistory()
  const [alert, setAlert] = useState(false)

  async function saveForm(data) {
    const r = await api
      .post('/organizacoes', {
        ...data,
        admin: parseInt(data.admin)
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Organização cadastrada!</Alert>)
      history.push(`/central/organizacoes/${r.data._id}/detalhes`)
    }
  }

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-building mr-1"></i>{' '}
            Organizações <i className="fas fa-angle-right mx-2"></i> Nova
            Organização
          </>
        }
      >
        <Button url={`/central/organizacoes`} icon="arrow-left">
          Voltar
        </Button>
      </PageTitle>

      {alert}

      <div className="box mt-5">
        <Form onSubmit={saveForm}>
          <Input name="name" label="Nome" type="text" required />
          <Checkbox
            name="admin"
            label="Administradora da Plataforma"
            value={1}
          />
          <Submit cancelUrl="/central/organizacoes" />
        </Form>
      </div>
    </>
  )
}
