import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import FormatDate from '../../components/FormatDate'

export default function List({ session }) {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const limit = process.env.REACT_APP_PAGINATION_LIMIT
  const [endPage, setEndPage] = useState(false)

  const loadList = useCallback(async () => {
    const r = await api.get(
      `/chaves?organization=${session.user.organization._id}&limit=${limit}&page=${page}`
    )
    if (!r.data || (Array.isArray(r.data) && r.data.length < limit))
      setEndPage(true)
    setList(previousList => (page > 1 ? previousList.concat(r.data) : r.data))
  }, [limit, page, session])

  useEffect(() => {
    loadList()
  }, [loadList])

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="fas fa-key mr-1"></i> Chaves de API's da{' '}
            {session.user.organization.name}
          </>
        }
      />

      <div className="box mt-5">
        {!list && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {Array.isArray(list) && (
          <>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>Chave</th>
                  <th>API</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan="2">Nenhum registro encontrado</td>
                  </tr>
                )}
                {list.length > 0 && (
                  <>
                    {list.map((registro, key) => (
                      <tr key={key}>
                        <td className="is-vcentered">
                          {registro.disabled ? (
                            <div>
                              <span className="tag is-danger">Desativada</span>
                            </div>
                          ) : (
                            ''
                          )}
                          <p className="m-0 has-text-grey-lighter">
                            <i className="fas fa-clock mr-2"></i>Criada em{' '}
                            <FormatDate timestamp={registro.created_in} />
                          </p>
                          <Link
                            to={`/central/chaves/${registro._id}/detalhes`}
                            className={`is-block is-size-4 has-text-weight-bold ${
                              registro.disabled ? 'has-text-danger' : ''
                            }`}
                          >
                            {registro._id}
                          </Link>
                          <p className="m-0">{registro.reason}</p>
                          <p className="m-0 mt-2 has-text-grey-lighter">
                            {registro.domains ? (
                              registro.domains.map((domain, domainKey) => (
                                <span
                                  className="tag is-dark mr-3"
                                  key={domainKey}
                                >
                                  <i className="fas fa-globe mr-2"></i>
                                  {domain.url}
                                </span>
                              ))
                            ) : (
                              <span className="has-text-danger">
                                <i className="fas fa-globe mr-2"></i> Sem
                                domínios configurados
                              </span>
                            )}
                          </p>
                        </td>
                        <td className="is-vcentered is-narrow">
                          <p className="title is-5 has-text-weight-bold m-0">
                            {registro.api.name}
                          </p>
                          <p className="has-text-grey-lighter my-2">
                            {registro.api.url}
                          </p>
                          <p className="m-0">
                            <span
                              className={`tag is-${
                                registro.test_environment ? 'danger' : 'info'
                              }`}
                            >
                              Ambiente de{' '}
                              {registro.test_environment
                                ? 'Testes'
                                : 'Produção'}
                            </span>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {!endPage && (
              <Button
                onClick={() => setPage(page + 1)}
                icon="sync"
                extraClass="is-dark"
              >
                Carregar mais
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}
