import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'

export default function List() {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const limit = 10
  const [endPage, setEndPage] = useState(false)

  useEffect(() => {
    async function loadList() {
      const r = await api.get(`/organizacoes?limit=${limit}&page=${page}`)
      if (!r.data || (Array.isArray(r.data) && r.data.length < limit))
        setEndPage(true)
      setList(previousList => previousList.concat(r.data))
    }
    loadList()
  }, [limit, page])

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-building mr-1"></i>{' '}
            Organizações
          </>
        }
      >
        <Button
          url={`/central/organizacoes/novo`}
          icon="plus-circle"
          extraClass="is-link has-text-dark"
        >
          Nova Organização
        </Button>
      </PageTitle>

      <div className="box mt-5">
        {!list && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {Array.isArray(list) && (
          <>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th className="is-narrow">Admin</th>
                  <th>Sigla</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan={2}>Nenhum registro encontrado</td>
                  </tr>
                )}
                {list.length > 0 && (
                  <>
                    {list.map((registro, key) => (
                      <tr key={key}>
                        <td className="has-text-centered">
                          <i
                            className={`fas fa-${
                              registro.admin ? 'check-circle' : 'times-circle'
                            } has-text-${
                              registro.admin ? 'success' : 'grey-light'
                            }`}
                          ></i>
                        </td>
                        <td>
                          <Link
                            to={`/central/organizacoes/${registro._id}/detalhes`}
                          >
                            {registro.name}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {!endPage && (
              <Button
                onClick={() => setPage(page + 1)}
                icon="sync"
                extraClass="is-dark"
              >
                Carregar mais
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}
