import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonDelete } from '../../../components/ButtonDelete'

export default function RemoverIp({ key_id, id, afterDelete }) {
  const [alert, setAlert] = useState()

  async function deleteIp() {
    const r = await api.delete(`/chaves/${key_id}/ips/${id}`)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">Ip removido!</Alert>)
      afterDelete()
    } else {
      setAlert(
        <Alert color="danger">
          Não foi possível remover o Ip.
          <br />
          Tente novamente ou entre em contato com o suporte da Central do
          Desenvolvedor.
        </Alert>
      )
    }
  }

  return (
    <>
      {alert}
      <ButtonDelete
        onClick={e => {
          e.preventDefault()
          if (window.confirm('Tem certeza que deseja remover este IP?'))
            deleteIp()
        }}
        title="Remover IP"
      ></ButtonDelete>
    </>
  )
}
