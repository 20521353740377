import React from 'react'
import { Link } from 'react-router-dom'

export default function Button({
  url = false,
  type = 'button',
  extraClass,
  icon,
  small = true,
  children,
  ...props
}) {
  return url ? (
    <Link
      to={url}
      className={`button ${small ? 'is-small' : ''} ${
        children ? 'mr-1' : ''
      } ${extraClass}`}
      {...props}
    >
      {icon && (
        <span className="icon">
          <i className={`fas fa-${icon}`}></i>
        </span>
      )}
      {children && (
        <span className="is-uppercase has-text-weight-bold">{children}</span>
      )}
    </Link>
  ) : (
    <button
      type={type}
      className={`button ${small ? 'is-small' : ''} ${
        children ? 'mr-1' : ''
      } ${extraClass}`}
      {...props}
    >
      {icon && (
        <span className="icon">
          <i className={`fas fa-${icon}`}></i>
        </span>
      )}
      {children && (
        <span className="is-uppercase has-text-weight-bold">{children}</span>
      )}
    </button>
  )
}
