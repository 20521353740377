import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Redirect } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import FormatDate from '../../components/FormatDate'
import { DetailBox } from '../../components/DetailBox'
import ListarPermissoes from './details/ListarPermissoes'
import ListarEscopos from './details/ListarEscopos'
import ListarEndpoints from './details/ListarEndpoints'

export default function Details({ session }) {
  const { id } = useParams()
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const [permissions, setPermissions] = useState([])

  const updatePermissions = __permissions => {
    setPermissions(__permissions)
  }

  const loadData = useCallback(async () => {
    const r = await api
      .get(`/apis/${id}`)
      .then(data => data)
      .catch(error => error.response)
    if (r.status !== 200) {
      setError(r.data)
    } else {
      setData(r.data)
    }
  }, [id])

  useEffect(() => {
    loadData()
  }, [loadData])

  return error ? (
    <>
      <Alert color="danger">{error.error.message}</Alert>
      <Redirect to="/central/apis" />
    </>
  ) : data && data.organization._id !== session.user.organization._id ? (
    <>
      <Alert color="danger">Esta API não pertence a sua organização!</Alert>
      <Redirect to="/central/apis" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-code mr-1"></i> API's{' '}
            <i className="fas fa-angle-right mx-2"></i> Detalhes da API
          </>
        }
      >
        <Button url="/central/apis" icon="arrow-left">
          Voltar
        </Button>
        {data && (
          <Button
            url={`/central/apis/${id}/editar`}
            icon="pen"
            extraClass="is-link"
          >
            Editar
          </Button>
        )}
      </PageTitle>

      <div className="mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {data && (
          <>
            <div className="box">
              <p className="mb-0 has-text-grey-light">
                <small>
                  API {data.public ? 'Pública' : 'Privada'} - Criada em{' '}
                  <FormatDate timestamp={data.created_in} />
                </small>
              </p>
              <h3 className="title is-3 mb-0">{data.name}</h3>
              <p className="mb-2 has-text-grey-light">{data.description}</p>
              <p className="mb-3">
                <span
                  className={`tag is-${
                    data.test_environment ? 'danger' : 'info'
                  }`}
                >
                  Ambiente de {data.test_environment ? 'Testes' : 'Produção'}
                </span>
              </p>
              <div>
                <Button
                  url={data.url.replace('https:', '').replace('http:', '')}
                  target="_blank"
                  icon="external-link-alt"
                >
                  URL
                </Button>
                {data.documentation_url && (
                  <Button
                    url={data.documentation_url
                      .replace('https:', '')
                      .replace('http:', '')}
                    target="_blank"
                    icon="external-link-alt"
                  >
                    Documentação
                  </Button>
                )}
              </div>
            </div>

            {data && (
              <>
                <DetailBox id="listaEndpoints" title="Endpoints" icon="link">
                  <ListarEndpoints
                    api_id={data._id}
                    permissions={permissions}
                  />
                </DetailBox>
                <div className="columns">
                  <div className="column is-half">
                    <DetailBox
                      id="listaPermissoes"
                      title="Permissões"
                      icon="unlock"
                    >
                      <ListarPermissoes
                        api_id={data._id}
                        afterUpdate={updatePermissions}
                      />
                    </DetailBox>
                  </div>
                  <div className="column is-half">
                    <DetailBox id="listaEscopo" title="Escopos" icon="filter">
                      <ListarEscopos api_id={data._id} />
                    </DetailBox>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}
