import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import AdicionarIp from './AdicionarIp'
import RemoverIp from './RemoverIp'

export default function ListarIps({ key_id, api_id, disabled, updateIps }) {
  const [ips, setIps] = useState(false)

  const loadIps = useCallback(async () => {
    const r = await api.get(`/chaves/${key_id}/ips`)
    setIps(r.data)
    if (r.data.length > 0)
      updateIps(
        r.data.map(({ ip }) => {
          return ip
        })
      )
    else updateIps([])
  }, [key_id, updateIps])

  useEffect(() => {
    loadIps()
  }, [loadIps])

  return (
    <>
      {!disabled && (
        <AdicionarIp
          key_id={key_id}
          api_id={api_id}
          afterCreate={() => loadIps()}
        />
      )}
      <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th colSpan={disabled ? 1 : 2}>Ip</th>
          </tr>
        </thead>
        <tbody>
          {!ips && (
            <tr>
              <td colSpan={disabled ? 1 : 2}>
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {ips && (
            <>
              {ips.length === 0 && (
                <tr>
                  <td colSpan={disabled ? 1 : 2}>Nenhum ip adicionado</td>
                </tr>
              )}
              {ips.length > 0 && (
                <>
                  {ips.map((ip, key) => (
                    <tr key={key}>
                      {!disabled && (
                        <td className="is-vcentered is-narrow">
                          <RemoverIp
                            key_id={key_id}
                            id={ip._id}
                            afterDelete={() => loadIps()}
                          />
                        </td>
                      )}
                      <td className="is-vcentered">{ip.ip}</td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
