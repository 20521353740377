import React, { useCallback, useEffect, useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonAdd } from '../../../components/ButtonAdd'

export default function AdicionarEscopo({ api_id, afterCreate }) {
  const [alert, setAlert] = useState()
  const [organization, setOrganization] = useState('')
  const [organizations, setOrganizations] = useState([])

  const loadOrganizations = useCallback(async () => {
    const r = await api.get(`/organizacoes`)
    setOrganizations(r.data)
  }, [])

  useEffect(() => {
    loadOrganizations()
  }, [loadOrganizations])

  async function saveEscopo(e) {
    e.preventDefault()
    const button = e.target
    button.disabled = true
    const r = await api
      .post(`/apis/${api_id}/escopos`, {
        organization
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Organização adicionada ao escopo!</Alert>)
      afterCreate()
    }
    button.disabled = false
  }

  return (
    <>
      {alert}
      <div className="py-3">
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    name="organization"
                    id="organization-key"
                    onChange={e => setOrganization(e.target.value)}
                  >
                    <option value="">Selecione...</option>
                    {organizations &&
                      organizations.length > 0 &&
                      organizations.map((organizacao, pkey) => (
                        <option key={pkey} value={organizacao._id}>
                          {organizacao.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <ButtonAdd small={false} onClick={e => saveEscopo(e)}></ButtonAdd>
          </div>
        </div>
      </div>
    </>
  )
}
