import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonAdd } from '../../../components/ButtonAdd'

export default function AdicionarIp({ key_id, afterCreate }) {
  const [alert, setAlert] = useState()
  const [ip, setIp] = useState('')

  async function saveIp(e) {
    e.preventDefault()
    const button = e.target
    button.disabled = true
    const r = await api
      .post(`/chaves/${key_id}/ips`, {
        key_id,
        ip
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Ip adicionado!</Alert>)
      afterCreate()
      setIp('')
    }
    button.disabled = false
  }

  return (
    <>
      {alert}
      <div className="mb-3">
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  id="ip-chave"
                  className="input"
                  placeholder="Endereço Ip"
                  type="text"
                  value={ip}
                  onChange={e => setIp(e.target.value)}
                />
              </div>
            </div>
            <ButtonAdd small={false} onClick={e => saveIp(e)}></ButtonAdd>
          </div>
        </div>
      </div>
    </>
  )
}
