import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'

export default function List({ session }) {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const limit = process.env.REACT_APP_PAGINATION_LIMIT
  const [endPage, setEndPage] = useState(false)

  useEffect(() => {
    async function loadList() {
      const r = await api.get(
        `/organizacoes/${session.user.organization._id}/apis?limit=${limit}&page=${page}&orderBy=name&order=ASC`
      )
      if (!r.data || (Array.isArray(r.data) && r.data.length < limit))
        setEndPage(true)
      setList(previousList => (page > 1 ? previousList.concat(r.data) : r.data))
    }
    loadList()
  }, [limit, page, session])

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-code mr-1"></i> API's
          </>
        }
      >
        <Button
          url={`/central/apis/nova`}
          icon="plus-circle"
          extraClass="is-link has-text-dark"
        >
          Nova API
        </Button>
      </PageTitle>

      <div className="box mt-5">
        {!list && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {Array.isArray(list) && (
          <>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th className="is-narrow">
                    <i
                      className="fas fa-globe has-text-grey-light"
                      title="Escopo"
                    ></i>
                  </th>
                  <th colSpan={2}>API</th>
                  <th className="is-narrow">Ambiente</th>
                  <th className="is-narrow">Organização</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan="5">Nenhum registro encontrado</td>
                  </tr>
                )}
                {list.length > 0 && (
                  <>
                    {list.map((registro, key) => (
                      <tr key={key}>
                        <td className="is-vcentered">
                          <i
                            className={`fas fa-${
                              registro.public ? 'globe' : 'lock'
                            } has-text-grey-light`}
                            title={
                              registro.public ? 'API Pública' : 'API Privada'
                            }
                          ></i>
                        </td>
                        <td className="is-narrow is-vcentered">
                          <Button
                            url={registro.url
                              .replace('https:', '')
                              .replace('http:', '')}
                            target="_blank"
                            icon="external-link-alt"
                          />
                        </td>
                        <td>
                          <Link
                            to={`/central/apis/${registro._id}/detalhes`}
                            className="is-block has-text-weight-bold"
                          >
                            {registro.name}
                          </Link>
                          <div className="is-size-7">{registro.url} </div>
                        </td>
                        <td className="is-vcentered">
                          <span
                            className={`tag is-${
                              registro.test_environment ? 'danger' : 'info'
                            }`}
                          >
                            {registro.test_environment ? 'Teste' : 'Produção'}
                          </span>
                        </td>
                        <td className="is-vcentered">
                          {registro.organization.name}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {!endPage && (
              <Button
                onClick={() => setPage(page + 1)}
                icon="sync"
                extraClass="is-dark"
              >
                Carregar mais
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}
