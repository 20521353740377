import React from 'react'
import { toast, setDoc } from 'bulma-toast'

export default function Alert({ color, delay = 3000, children }) {
  const date = new Date()

  setDoc(window.document)

  return (
    <>
      {toast({
        message: `<div><span class="is-size-7 is-block m-0 p-0" style="opacity: 0.75">${date.toLocaleString(
          'pt-BR'
        )}</span>${children}</div>`,
        type: `is-${color}`,
        dismissable: true,
        duration: delay,
        pauseOnHover: true,
        position: 'top-center'
      })}
    </>
  )
}
