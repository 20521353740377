import React from 'react'
import { Link } from 'react-router-dom'

export default function Submit({ cancelUrl, disabled, ...rest }) {
  return (
    <>
      <div className="field is-grouped">
        <p className="control">
          <button
            className="button is-link is-small has-text-dark"
            type="submit"
            disabled={disabled}
          >
            <span className="icon">
              <i className="fas fa-save"></i>
            </span>
            <span className="is-uppercase has-text-weight-bold">Salvar</span>
          </button>
        </p>
        <p className="control">
          <Link className="button is-small" to={cancelUrl}>
            <span className="icon">
              <i className="fas fa-ban"></i>
            </span>
            <span className="is-uppercase has-text-weight-bold">Cancelar</span>
          </Link>
        </p>
      </div>
    </>
  )
}
