import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import api from '../services/api'
import PageTitle from '../components/PageTitle'
import Button from '../components/Button'
import FormatDate from '../components/FormatDate'

export default function List({ session }) {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const limit = process.env.REACT_APP_PAGINATION_LIMIT
  const [endPage, setEndPage] = useState(false)
  const { _id: user_id } = session.user

  const loadList = useCallback(async () => {
    const r = await api.get(
      `/notificacoes/${user_id}?limit=${limit}&page=${page}`
    )
    if (!r.data || (Array.isArray(r.data) && r.data.length < limit))
      setEndPage(true)
    setList(previousList => (page > 1 ? previousList.concat(r.data) : r.data))
  }, [limit, page, user_id])

  useEffect(() => {
    loadList()
  }, [loadList])

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-bell mr-1"></i>{' '}
            Notificações
          </>
        }
      ></PageTitle>

      <div className="box mt-5">
        {!list && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {Array.isArray(list) && (
          <>
            <table className="table is-fullwidth">
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td>Nenhuma notificação encontrada</td>
                  </tr>
                )}
                {list.length > 0 && (
                  <>
                    {list.map((registro, key) => (
                      <tr key={key}>
                        <td>
                          <div className="has-text-grey-lighter is-size-7">
                            <i className="fas fa-clock has-text-grey-light mr-2"></i>
                            <FormatDate timestamp={registro.created_in} />
                          </div>
                          <Link to={`/central/${registro.url}`}>
                            {registro.message}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {!endPage && (
              <Button
                onClick={() => setPage(page + 1)}
                icon="sync"
                extraClass="is-dark"
              >
                Carregar mais
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}
