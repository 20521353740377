import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import AdicionarUsuario from './AdicionarAdministrador'
import RemoverUsuario from './RemoverAdministrador'
import CPF from 'cpf-check'

export default function ListarAdministradores({ organization_id }) {
  const [usuarios, setUsuarios] = useState(false)

  const loadUsuarios = useCallback(async () => {
    const r = await api.get(
      `/organizacoes/${organization_id}/usuarios?admin=true`
    )
    setUsuarios(r.data)
  }, [organization_id])

  useEffect(() => {
    loadUsuarios()
  }, [loadUsuarios])

  return (
    <>
      <AdicionarUsuario
        organization_id={organization_id}
        usuarios={usuarios}
        afterCreate={loadUsuarios}
      />
      <table className="table is-fullwidth is-hoverable mt-2">
        <thead>
          <tr>
            <th className="has-text-centered has-text-grey-light is-narrow">
              <i className="fas fa-trash"></i>
            </th>
            <th>CPF</th>
            <th>Nome</th>
          </tr>
        </thead>
        <tbody>
          {!usuarios && (
            <tr>
              <td colSpan="3">
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {usuarios && (
            <>
              {usuarios.length === 0 && (
                <tr>
                  <td colSpan="3">
                    Não há usuário(s) administrador(es) nesta Organização
                  </td>
                </tr>
              )}
              {usuarios.length > 0 && (
                <>
                  {usuarios.map((usuario, key) => (
                    <tr key={key}>
                      <td className="is-vcentered">
                        <RemoverUsuario
                          organization_id={organization_id}
                          user_id={usuario._id}
                          afterDelete={loadUsuarios}
                        />
                      </td>
                      <td className="is-vcentered is-narrow">
                        {CPF.format(usuario.cpf)}
                      </td>
                      <td className="is-vcentered">{usuario.name}</td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
