import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Redirect, Link } from 'react-router-dom'
import copy from 'copy-to-clipboard'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import FormatDate from '../../components/FormatDate'
import { DetailBox } from '../../components/DetailBox'
import ListarPermissoes from '../MinhasChaves/details/ListarPermissoes'
import ListarIps from '../MinhasChaves/details/ListarIps'
import ListarDominios from '../MinhasChaves/details/ListarDominios'
import ListarEndpoints from '../MinhasChaves/details/ListarEndpoints'
import ListarUsuarios from '../MinhasChaves/details/ListarUsuarios'

export default function Details() {
  const { id } = useParams()
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const [copiado, setCopiado] = useState(false)
  const [permissions, setPermissions] = useState(false)

  const loadData = useCallback(async () => {
    const r = await api
      .get(`/logs/${id}`)
      .then(res => res)
      .catch(err => err.response.data)
    if (r.error) {
      setError(r.error)
    } else {
      setData(r.data)
    }
  }, [id])

  useEffect(() => {
    loadData()
  }, [loadData])

  const tagMethod = __method => {
    switch (__method.toLowerCase()) {
      case 'get':
        return 'success'
      case 'post':
        return 'warning'
      case 'put':
        return 'info'
      case 'patch':
        return 'info'
      case 'delete':
        return 'danger'
      case 'options':
        return 'dark'
      case 'head':
        return 'dark'
      default:
        return 'light'
    }
  }

  const decodeQueryString = url => {
    const parts = url.split('?')
    if (parts.length === 1) return
    const params = parts[1].split('&')
    const res = {}
    params.map(param => {
      const p = param.split('=')
      return (res[p[0]] = p[1])
    })
    return res
  }

  return error ? (
    <>
      <Alert color="danger">{error.message}</Alert>
      <Redirect to="/central/logs" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-file-alt mr-1"></i> Logs{' '}
            <i className="fas fa-angle-right mx-2"></i> Detalhes do Log
          </>
        }
      >
        <Button url="/central/logs" icon="arrow-left">
          Voltar
        </Button>
      </PageTitle>

      <div className="mt-5">
        <p>
          <i className="fas fa-clock has-text-grey-light mr-1"></i> Registrado
          em <FormatDate timestamp={data.created_in} />
        </p>

        {data && (
          <>
            <div className="box mt-5">
              <h5 className="is-size-5 mb-2">
                <i className="fas fa-globe mr-1"></i> Requisição
              </h5>
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Origem</th>
                    <th>URL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="is-narrow">
                      <strong>{data.request.body.origin}</strong>
                    </td>
                    <td>
                      <span
                        className={`tag is-${tagMethod(
                          data.request.body.method
                        )} mr-2`}
                      >
                        {data.request.body.method}
                      </span>
                      <span className="has-text-grey-lighter">
                        {data.key.api.url}
                      </span>
                      <strong>
                        {data.request.body.endpoint.split('?')[0]}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="box mt-5">
              <h5 className="is-size-5 mb-2">
                <i className="fas fa-code mr-1"></i> Parâmetros
              </h5>
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>QueryString</th>
                    <th>Body</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <code>
                        {JSON.stringify(
                          decodeQueryString(data.request.body.endpoint),
                          null,
                          2
                        )}
                      </code>
                    </td>
                    <td>
                      <code>
                        {JSON.stringify(data.request.body?.body, null, 2)}
                      </code>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="box mt-5">
              <h5 className="is-size-5 mb-2">
                <i className="fas fa-server mr-1"></i> Resposta
              </h5>
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th className="is-narrow">Status</th>
                    <th>Permissão</th>
                    <th>Endpoint</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        className={`tag is-${
                          data.status === 200 ? 'success' : 'danger'
                        }`}
                      >
                        {data.status}
                      </span>
                    </td>
                    <td className="is-narrow">
                      {data.response?.permissions[0]}
                    </td>
                    <td>{data.response?.endpoints[0]}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>

      <div className="mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {data && (
          <>
            <h4 className="title is-4 mt-5 mb-3">
              <i className="fas fa-key has-text-link mr-1"></i> Chave
            </h4>
            <small className="has-text-grey-lighter">
              <i className="fas fa-clock has-text-grey-light mr-1"></i> Criada
              em <FormatDate timestamp={data.key.created_in} />
            </small>
            <h2
              className={`is-family-monospace title is-3 is-marginless${
                data.key.disabled ? ' has-text-danger' : ''
              }`}
            >
              {data.key._id}
            </h2>
            {data.key.disabled && (
              <span className="tag is-danger is-uppercase">Desativada</span>
            )}
            <div className="mt-3">
              <button
                type="button"
                className={`button is-small ${
                  copiado ? null : 'is-outlined'
                } is-link is-uppercase has-text-weight-bold`}
                onClick={e => {
                  e.preventDefault()
                  if (!copiado) {
                    setCopiado(true)
                    copy(data.key._id)
                    setInterval(function () {
                      setCopiado(false)
                    }, 3000)
                  }
                }}
              >
                <i
                  className={`fas fa-${copiado ? 'check-circle' : 'copy'} mr-1`}
                ></i>
                {copiado ? 'Copiado!' : 'Copiar'}
              </button>
            </div>
            <div className="notification is-danger is-light mt-5">
              Os detalhes a seguir representam a chave no momento deste registro
              de Log, para visualizar o estado atual da chave,{' '}
              <Link
                to={`/central/chaves/${data.key._id}/detalhes`}
                target="_blank"
                className="has-text-danger"
              >
                clique aqui
              </Link>
              .
            </div>
            <div className="box mt-5">
              <table className="table is-fullwidth mt-3">
                <tbody>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-code"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      API
                    </td>
                    <td>{data.key.api.name}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-server"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Ambiente
                    </td>
                    <td>
                      {data.key.api.test_environment ? 'Testes' : 'Produção'}
                    </td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-info-circle"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Objetivo
                    </td>
                    <td>{data.key.reason}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-link"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Links
                    </td>
                    <td>
                      <Button
                        url={data.key.api.url
                          .replace('https:', '')
                          .replace('http:', '')}
                        target="_blank"
                        icon="external-link-alt"
                        extraClass="is-small"
                      >
                        URL
                      </Button>
                      {data.key.api.documentation_url && (
                        <Button
                          url={data.key.api.documentation_url
                            .replace('https:', '')
                            .replace('http:', '')}
                          target="_blank"
                          icon="external-link-alt"
                          extraClass="is-small"
                        >
                          Documentação
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      {data && (
        <>
          <div className="columns is-multiline">
            <div className="column is-full-desktop is-half-widescreen is-one-third-fullhd mt-5 pb-0">
              <DetailBox title="Permissões" icon="unlock">
                <ListarPermissoes
                  key_id={data.key._id}
                  api_id={data.key.api._id}
                  disabled={true}
                  updatePermissions={setPermissions}
                />
              </DetailBox>
              <DetailBox title="Domínios" icon="link">
                <ListarDominios
                  key_id={data.key._id}
                  api_id={data.key.api._id}
                  disabled={true}
                  updateDomains={v => {}}
                />
              </DetailBox>
              <DetailBox title="IP's" icon="server">
                <ListarIps
                  key_id={data.key._id}
                  api_id={data.key.api._id}
                  disabled={true}
                  updateIps={v => {}}
                />
              </DetailBox>
            </div>
            <div className="column is-full-desktop is-half-widescreen is-two-thirds-fullhd mt-5">
              <DetailBox
                id="listaUsuarios"
                title="Desenvolvedores"
                icon="users"
                description={
                  <>Usuários com acesso ao gerenciamento desta chave.</>
                }
              >
                <ListarUsuarios
                  key_id={data.key._id}
                  api_id={data.key.api._id}
                  api_scope={data.key.api.scopes}
                  api_public={data.key.api.public}
                  disabled={true}
                />
              </DetailBox>
              <DetailBox id="listaEndpoints" title="Endpoints" icon="link">
                <ListarEndpoints
                  api_id={data.key.api._id}
                  permissions={permissions}
                />
              </DetailBox>
            </div>
          </div>
        </>
      )}
    </>
  )
}
