import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonDelete } from '../../../components/ButtonDelete'

export default function ApagarEndpoint({ api_id, endpoint_id, afterDelete }) {
  const [alert, setAlert] = useState()

  async function deleteEndpoint() {
    const r = await api.delete(`/apis/${api_id}/endpoints/${endpoint_id}`)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">Endpoint apagado!</Alert>)
      afterDelete()
    } else {
      setAlert(
        <Alert color="danger">
          Não foi possível excluir o Endpoint.
          <br />
          Tente novamente ou entre em contato com o suporte da Central do
          Desenvolvedor.
        </Alert>
      )
    }
  }

  return (
    <>
      {alert}
      <ButtonDelete
        onClick={e => {
          e.preventDefault()
          if (window.confirm('Tem certeza que deseja remover este endpoint?'))
            deleteEndpoint()
        }}
        title="Remover endpoint"
      ></ButtonDelete>
    </>
  )
}
