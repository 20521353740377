import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonDelete } from '../../../components/ButtonDelete'

export default function RemoverUsuario({ key_id, id, afterDelete }) {
  const [alert, setAlert] = useState()

  async function deleteUsuario() {
    const r = await api
      .delete(`/chaves/${key_id}/usuarios/${id}`)
      .then(data => data)
      .catch(error => error.response.data)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">Usuário removida!</Alert>)
      afterDelete()
    } else {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    }
  }

  return (
    <>
      {alert}
      <ButtonDelete
        onClick={e => {
          e.preventDefault()
          if (window.confirm('Tem certeza que deseja remover este usuário?'))
            deleteUsuario()
        }}
        title="Remover desenvolvedor"
      ></ButtonDelete>
    </>
  )
}
