import List from './list'
import Details from './details'
import New from './new'
import Update from './update'

export default {
  list: List,
  details: Details,
  new: New,
  update: Update
}
