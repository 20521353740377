import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import AdicionarPermissao from './AdicionarPermissao'
import ApagarPermissao from './ApagarPermissao'

export default function ListarPermissoes({ api_id, afterUpdate = false }) {
  const [permissoes, setPermissoes] = useState(false)

  const loadPermissoes = useCallback(async () => {
    const r = await api.get(`/apis/${api_id}/permissoes`)
    setPermissoes(r.data)
  }, [api_id])

  useEffect(() => {
    loadPermissoes()
  }, [loadPermissoes])

  useEffect(() => {
    if (permissoes && afterUpdate) afterUpdate(permissoes)
  }, [permissoes, afterUpdate])

  return (
    <>
      <AdicionarPermissao api_id={api_id} afterCreate={loadPermissoes} />
      <table className="table is-fullwidth mt-3">
        <thead>
          <tr>
            <th className="has-text-centered has-text-grey-light is-narrow">
              <i className="fas fa-trash"></i>
            </th>
            <th>Permissão</th>
            <th className="is-narrow">Requer Moderação?</th>
          </tr>
        </thead>
        <tbody>
          {!permissoes && (
            <tr>
              <td colSpan="3">
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {permissoes && (
            <>
              {permissoes.length === 0 && (
                <tr>
                  <td colSpan="3">Nenhuma permissão adicionada</td>
                </tr>
              )}
              {permissoes.length > 0 && (
                <>
                  {permissoes.map((permissao, key) => (
                    <tr key={key}>
                      <td className="is-vcentered">
                        <ApagarPermissao
                          api_id={api_id}
                          permission_id={permissao._id}
                          afterDelete={loadPermissoes}
                        />
                      </td>
                      <td className="is-vcentered">{permissao.name}</td>
                      <td className="is-vcentered is-size-7">
                        <i
                          className={`fas fa-${
                            permissao.moderate ? 'lock' : 'check'
                          } mr-2`}
                        ></i>{' '}
                        {permissao.moderate
                          ? 'Sim, é moderada'
                          : 'Não, é liberada'}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
