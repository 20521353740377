import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import Button from '../../../components/Button'
import Alert from '../../../components/Alert'

export default function ListarPermissoes({ key_id, api_id, disabled }) {
  const [permissoes, setPermissoes] = useState(false)
  const [alert, setAlert] = useState()

  const loadPermissoes = useCallback(async () => {
    const r = await api.get(`/chaves/${key_id}/permissoes`)
    setPermissoes(r.data)
  }, [key_id])

  async function approvePermission(permission_id) {
    const r = await api
      .put(`/chaves/${key_id}/permissoes/${permission_id}/aprovar`)
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Permissão aprovada!</Alert>)
      loadPermissoes()
    }
  }

  async function disapprovePermission(permission_id) {
    const reason = window.prompt('Elabore uma justificativa para a reprovação:')
    if (!reason) {
      setAlert(
        <Alert color="danger">
          Erro! Para reprovar uma permissão, é obrigatória a presença de uma
          justificativa.
        </Alert>
      )
      return
    }
    const r = await api
      .put(`/chaves/${key_id}/permissoes/${permission_id}/reprovar`, { reason })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Permissão reprovada!</Alert>)
      loadPermissoes()
    }
  }

  useEffect(() => {
    loadPermissoes()
  }, [loadPermissoes])

  return (
    <>
      {alert}
      <table className="table is-fullwidth mt-5">
        <thead>
          <tr>
            <th>Permissão</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {!permissoes && (
            <tr>
              <td colSpan="2">
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {permissoes && (
            <>
              {permissoes.length === 0 && (
                <tr>
                  <td colSpan="2">Nenhuma permissão adicionada</td>
                </tr>
              )}
              {permissoes.length > 0 && (
                <>
                  {permissoes.map((permissao, key) => (
                    <tr key={key}>
                      <td className="is-vcentered is-narrow">
                        {permissao.permission.name}
                      </td>
                      <td
                        className={`is-vcentered has-text-${
                          permissao.permission.moderate
                            ? permissao.approved
                              ? 'info'
                              : permissao.disapproved
                              ? 'danger'
                              : 'danger'
                            : 'grey'
                        }`}
                      >
                        <div>
                          <i
                            className={`fas fa-${
                              permissao.permission.moderate
                                ? permissao.approved
                                  ? 'check-square'
                                  : permissao.disapproved
                                  ? 'ban'
                                  : 'clock'
                                : 'check'
                            } mr-1`}
                          ></i>{' '}
                          {permissao.permission.moderate
                            ? permissao.approved
                              ? 'Aprovada'
                              : permissao.disapproved
                              ? 'Reprovada: ' + permissao.reason
                              : 'Aguardando moderação'
                            : 'Liberada'}
                        </div>
                        {!permissao.approved &&
                          !permissao.disapproved &&
                          permissao.permission && (
                            <div className="mt-2">
                              <Button
                                icon="check"
                                extraClass="is-primary is-small"
                                onClick={() => approvePermission(permissao._id)}
                              />
                              &nbsp;&nbsp;
                              <Button
                                icon="ban"
                                extraClass="is-danger is-small"
                                onClick={() =>
                                  disapprovePermission(permissao._id)
                                }
                              />
                            </div>
                          )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
