import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Redirect } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import FormatDate from '../../components/FormatDate'
import { DetailBox } from '../../components/DetailBox'
import ListarPermissoes from './details/ListarPermissoes'
import ListarIps from '../MinhasChaves/details/ListarIps'
import ListarDominios from '../MinhasChaves/details/ListarDominios'
import ListarEndpoints from '../MinhasChaves/details/ListarEndpoints'
import ListarUsuarios from '../MinhasChaves/details/ListarUsuarios'

export default function Details({ session }) {
  const { id } = useParams()
  const [data, setData] = useState(false)
  const [permissions, setPermissions] = useState(false)
  const [notFound, setNotFound] = useState(false)

  const loadData = useCallback(async () => {
    const r = await api
      .get(`/chaves/${id}`)
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setNotFound(r.error)
    } else {
      setData(r.data)
    }
  }, [id])

  useEffect(() => {
    loadData()
  }, [loadData])

  return notFound ? (
    <>
      <Alert color="danger">{notFound.message}</Alert>
      <Redirect to="/central/moderar" />
    </>
  ) : data && data.api.organization._id !== session.user?.organization._id ? (
    <>
      <Alert color="danger">
        Esta chave não pertence a uma API da sua organização!
      </Alert>
      <Redirect to="/central/moderar" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-search mr-1"></i> Moderar
            Chaves <i className="fas fa-angle-right mx-2"></i> Detalhes da Chave
          </>
        }
      >
        <Button url="/central/moderar" icon="arrow-left">
          Voltar
        </Button>
      </PageTitle>

      <div className="mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {data && (
          <>
            <div className="mb-5">
              <p className="has-text-grey-light mb-2">
                <i className="fas fa-clock mr-2"></i> Chave criada em{' '}
                <FormatDate timestamp={data.created_in} />
              </p>
              <h2
                className={`is-family-monospace title is-3 has-text-weight-bold mb-2${
                  data.disabled || (!data.api.public && session.user?.public)
                    ? ' has-text-danger'
                    : ''
                }`}
              >
                {data._id}
              </h2>
              {data.disabled ||
                (!data.api.public && session.user?.public && (
                  <span className="tag is-danger is-uppercase">Desativada</span>
                ))}
            </div>

            <div className="box">
              <table className="table is-fullwidth">
                <tbody>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-code"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      API
                    </td>
                    <td>{data.api.name}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-server"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Ambiente
                    </td>
                    <td>{data.api.test_environment ? 'Testes' : 'Produção'}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-info-circle"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Objetivo
                    </td>
                    <td>{data.reason}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-link"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Links
                    </td>
                    <td>
                      <Button
                        url={data.api.url
                          .replace('https:', '')
                          .replace('http:', '')}
                        target="_blank"
                        icon="external-link-alt"
                        extraClass="is-small"
                      >
                        URL
                      </Button>
                      {data.api.documentation_url && (
                        <Button
                          url={data.api.documentation_url
                            .replace('https:', '')
                            .replace('http:', '')}
                          target="_blank"
                          icon="external-link-alt"
                          extraClass="is-small"
                        >
                          Documentação
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      {data && (
        <>
          <div className="columns is-multiline">
            <div className="column is-full-desktop is-half-widescreen is-one-third-fullhd mt-5 pb-0">
              <DetailBox title="Permissões" icon="unlock">
                <ListarPermissoes
                  key_id={id}
                  api_id={data.api._id}
                  disabled={true}
                  updatePermissions={setPermissions}
                />
              </DetailBox>
              <DetailBox title="Domínios" icon="link">
                <ListarDominios
                  key_id={id}
                  api_id={data.api._id}
                  disabled={true}
                  updateDomains={v => {}}
                />
              </DetailBox>
              <DetailBox title="IP's" icon="server">
                <ListarIps
                  key_id={id}
                  api_id={data.api._id}
                  disabled={true}
                  updateIps={v => {}}
                />
              </DetailBox>
            </div>
            <div className="column is-full-desktop is-half-widescreen is-two-thirds-fullhd mt-5">
              <DetailBox
                id="listaUsuarios"
                title="Desenvolvedores"
                icon="users"
              >
                <ListarUsuarios
                  key_id={id}
                  api_id={data.api._id}
                  api_scope={data.api.scopes}
                  api_public={data.api.public}
                  disabled={true}
                />
              </DetailBox>
              <DetailBox id="listaEndpoints" title="Endpoints" icon="link">
                <ListarEndpoints
                  api_id={data.api._id}
                  permissions={permissions}
                />
              </DetailBox>
            </div>
          </div>
        </>
      )}
    </>
  )
}
