import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'

import CPF from 'cpf-check'

export default function List() {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const limit = process.env.REACT_APP_PAGINATION_LIMIT
  const [endPage, setEndPage] = useState(false)

  const loadList = useCallback(async () => {
    const r = await api.get(`/usuarios?limit=${limit}&page=${page}`)
    if (!r.data || (Array.isArray(r.data) && r.data.length < limit))
      setEndPage(true)
    setList(previousList => (page > 1 ? previousList.concat(r.data) : r.data))
  }, [limit, page])

  useEffect(() => {
    loadList()
  }, [loadList])

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-users mr-1"></i> Usuários
          </>
        }
      />

      <div className="box mt-5">
        {!list && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {Array.isArray(list) && (
          <>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th className="is-narrow">
                    <i
                      className="fas fa-globe has-text-grey"
                      title="Escopo"
                    ></i>
                  </th>
                  <th>Nome</th>
                  <th>CPF</th>
                  <th>Organização</th>
                  <th className="is-narrow">Admin</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan="5">Nenhum registro encontrado</td>
                  </tr>
                )}
                {list.length > 0 && (
                  <>
                    {list.map((registro, key) => (
                      <tr key={key}>
                        <td>
                          <i
                            className={`fas fa-${
                              registro.public ? 'globe' : 'lock'
                            } has-text-grey`}
                            title={
                              registro.public
                                ? 'Usuário Público'
                                : 'Usuário Privado'
                            }
                          ></i>
                        </td>
                        <td>
                          <Link
                            to={`/central/usuarios/${registro._id}/detalhes`}
                            className="is-block"
                          >
                            {registro.name}
                          </Link>
                        </td>
                        <td>{CPF.format(registro.cpf)}</td>
                        <td>
                          {registro.organization
                            ? registro.organization.name
                            : '---'}
                        </td>
                        <td>
                          <span
                            className={`icon has-text-${
                              registro.admin ? 'success' : 'danger'
                            }`}
                          >
                            {registro.admin ? (
                              <i className="fas fa-check"></i>
                            ) : (
                              <i className="fas fa-ban"></i>
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {!endPage && (
              <Button
                onClick={() => setPage(page + 1)}
                icon="sync"
                extraClass="is-dark"
              >
                Carregar mais
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}
