import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import copy from 'copy-to-clipboard'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import FormatDate from '../../components/FormatDate'
import { DetailBox } from '../../components/DetailBox'
import ListarPermissoes from '../MinhasChaves/details/ListarPermissoes'
import ListarIps from '../MinhasChaves/details/ListarIps'
import ListarDominios from '../MinhasChaves/details/ListarDominios'
import ListarEndpoints from '../MinhasChaves/details/ListarEndpoints'
import ListarUsuarios from '../MinhasChaves/details/ListarUsuarios'

export default function Details({ session }) {
  const { id } = useParams()
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const [copiado, setCopiado] = useState(false)
  const [permissions, setPermissions] = useState(false)

  const loadData = useCallback(async () => {
    const r = await api
      .get(`/chaves/${id}`)
      .then(response => {
        const { api } = response.data
        if (
          session.user?.organization &&
          api &&
          api.organization._id !== session.user.organization._id
        ) {
          return {
            error: {
              message:
                'Você não tem permissão para visualizar esta chave, ela pertence a uma API de outra organização.'
            }
          }
        } else {
          return response
        }
      })
      .catch(err => err.response)
    if (r.data?.error) {
      setError(r.data.error)
    } else {
      setData(r.data)
    }
  }, [id, session])

  useEffect(() => {
    loadData()
  }, [loadData])

  const disableKey = useCallback(
    async function () {
      if (!data) return
      await api
        .put(`/chaves/${data._id}`, { disabled: true })
        .then(data => loadData())
        .catch(err => setError(err.response.data.error))
    },
    [data, loadData]
  )

  const enableKey = useCallback(
    async function (key = false) {
      if (!data || !key) return
      await api
        .put(`/chaves/${data._id}`, { disabled: null })
        .then(data => loadData())
        .catch(err => setError(err.response.data.error))
    },
    [data, loadData]
  )

  return error ? (
    <>
      <Alert color="danger">{error.message}</Alert>
      <Redirect to="/central/chaves" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-key mr-2"></i> Chaves{' '}
            <i className="fas fa-angle-right mx-2"></i> Detalhes da Chave
          </>
        }
      >
        <Button url="/central/chaves" icon="arrow-left" extraClass="mr-3">
          Voltar
        </Button>
        {data.disabled ? (
          <Button extraClass="is-info" onClick={enableKey}>
            <i className="fas fa-unlock mr-2"></i>
            Desbloquear
          </Button>
        ) : (
          <Button extraClass="is-danger" onClick={disableKey}>
            <i className="fas fa-lock mr-2"></i>
            Bloquear
          </Button>
        )}
      </PageTitle>

      <div className="mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {data && (
          <>
            <div className="mb-5">
              <p className="has-text-grey-light mb-2">
                <i className="fas fa-clock mr-2"></i> Chave criada em{' '}
                <FormatDate timestamp={data.created_in} />
              </p>
              {data.disabled && (
                <span className="tag is-danger is-uppercase">Desativada</span>
              )}
              <h2
                className={`is-family-monospace title is-3 has-text-weight-bold mt-2 mb-2${
                  data.disabled || (!data.api.public && session.user?.public)
                    ? ' has-text-danger'
                    : ''
                }`}
              >
                {data._id}
              </h2>
              {data.disabled ||
                (!data.api.public && session.user?.public && (
                  <span className="tag is-danger is-uppercase">Desativada</span>
                ))}
              <button
                type="button"
                className={`button is-small mt-1 ${
                  copiado ? 'has-text-dark' : 'is-outlined'
                } is-link is-uppercase has-text-weight-bold`}
                onClick={e => {
                  e.preventDefault()
                  if (!copiado) {
                    setCopiado(true)
                    copy(data._id)
                    setInterval(function () {
                      setCopiado(false)
                    }, 3000)
                  }
                }}
              >
                <i
                  className={`fas fa-${copiado ? 'check-circle' : 'copy'} mr-1`}
                ></i>
                {copiado ? 'Copiado!' : 'Copiar'}
              </button>
            </div>

            <div className="box">
              <table className="table is-fullwidth">
                <tbody>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-code"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      API
                    </td>
                    <td>{data.api.name}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-server"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Ambiente
                    </td>
                    <td>{data.api.test_environment ? 'Testes' : 'Produção'}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-info-circle"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Objetivo
                    </td>
                    <td>{data.reason}</td>
                  </tr>
                  <tr>
                    <td className="is-narrow has-text-grey-light">
                      <i className="fas fa-link"></i>
                    </td>
                    <td className="is-narrow is-vcentered has-text-weight-bold">
                      Links
                    </td>
                    <td>
                      <Button
                        url={data.api.url
                          .replace('https:', '')
                          .replace('http:', '')}
                        target="_blank"
                        icon="external-link-alt"
                        extraClass="is-small"
                      >
                        URL
                      </Button>
                      {data.api.documentation_url && (
                        <Button
                          url={data.api.documentation_url
                            .replace('https:', '')
                            .replace('http:', '')}
                          target="_blank"
                          icon="external-link-alt"
                          extraClass="is-small"
                        >
                          Documentação
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      {data && (
        <>
          <div className="columns is-multiline">
            <div className="column is-full-desktop is-half-widescreen is-one-third-fullhd mt-5 pb-0">
              <DetailBox title="Permissões" icon="unlock">
                <ListarPermissoes
                  key_id={id}
                  api_id={data.api._id}
                  disabled={true}
                  updatePermissions={setPermissions}
                />
              </DetailBox>
              <DetailBox
                title="Domínios"
                icon="link"
                description={
                  data.disabled && (
                    <p className="notification is-danger m-0">
                      Esta chave está bloqueada, todas as origens anteriormente
                      configuradas estão bloqueadas de consumir esta chave
                      também.
                    </p>
                  )
                }
              >
                <ListarDominios
                  key_id={id}
                  api_id={data.api._id}
                  disabled={true}
                  updateDomains={v => {}}
                />
              </DetailBox>
              <DetailBox
                title="IP's"
                icon="server"
                description={
                  data.disabled && (
                    <p className="notification is-danger m-0">
                      Esta chave está bloqueada, todas as origens anteriormente
                      configuradas estão bloqueadas de consumir esta chave
                      também.
                    </p>
                  )
                }
              >
                <ListarIps
                  key_id={id}
                  api_id={data.api._id}
                  disabled={true}
                  updateIps={v => {}}
                />
              </DetailBox>
            </div>
            <div className="column is-full-desktop is-half-widescreen is-two-thirds-fullhd mt-5">
              <DetailBox
                id="listaUsuarios"
                title="Desenvolvedores"
                icon="users"
              >
                <ListarUsuarios
                  key_id={id}
                  api_id={data.api._id}
                  api_scope={data.api.scopes}
                  api_public={data.api.public}
                  disabled={true}
                />
              </DetailBox>
              <DetailBox
                id="listaEndpoints"
                title="Endpoints"
                icon="link"
                description={
                  data.disabled && (
                    <p className="notification is-danger m-0">
                      Esta chave está desabilitada, todos endpoints
                      anteriormente liberados através das permissões adicionadas
                      estão bloqueados.
                    </p>
                  )
                }
              >
                <ListarEndpoints
                  api_id={data.api._id}
                  permissions={permissions}
                />
              </DetailBox>
            </div>
          </div>
        </>
      )}
    </>
  )
}
