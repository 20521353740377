import React, { useRef, useState, useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { Form } from '@unform/web'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import { Input, Checkbox, Submit } from '../../components/Formulario'
import Alert from '../../components/Alert'

export default function Update() {
  const { uid } = useParams()
  const formRef = useRef()
  const history = useHistory()
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const [alert, setAlert] = useState(false)

  useEffect(() => {
    async function loadData() {
      const r = await api
        .get(`/organizacoes/${uid}`)
        .then(data => data)
        .catch(error => error.response)
      if (r.status !== 200) {
        setError(r.data)
      } else {
        setData(r.data)
      }
    }
    loadData()
  }, [uid])

  async function saveForm(data) {
    const r = await api
      .put(`/organizacoes/${uid}`, {
        ...data,
        admin: parseInt(data.admin)
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Organização atualizada!</Alert>)
    }
  }

  async function deleteOrganizacao() {
    const r = await api.delete(`/organizacoes/${uid}`)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">Organização apagada!</Alert>)
      history.push('/central/organizacoes')
    } else {
      setAlert(
        <Alert color="danger">
          Não foi possível excluir a Organização.
          <br />
          Tente novamente ou entre em contato com o suporte da Central do
          Desenvolvedor.
        </Alert>
      )
    }
  }

  return error ? (
    <>
      <Alert color="danger">{error.error.message}</Alert>
      <Redirect to="/central/organizacoes" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-users mr-1"></i>{' '}
            Organizações
          </>
        }
      >
        <Button url={`/central/organizacoes/${uid}/detalhes`} icon="arrow-left">
          Voltar
        </Button>
        <Button
          url="#delete"
          onClick={e => {
            e.preventDefault()
            if (
              window.confirm(
                `Tem certeza que deseja apagar a organização ${data.name}?`
              )
            )
              deleteOrganizacao()
          }}
          icon="trash"
          extraClass="is-danger"
        >
          Apagar
        </Button>
      </PageTitle>

      <div className="mt-5">
        <p className="has-text-grey mb-3">Editar Organização</p>
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {alert}
        {data && (
          <Form ref={formRef} initialData={data} onSubmit={saveForm}>
            <Input name="name" label="Nome" type="text" />
            <Checkbox
              name="admin"
              label="Administradora da Plataforma"
              value={1}
              defaultChecked={data.admin ? true : false}
            />
            <Submit cancelUrl={`/central/organizacoes/${uid}/detalhes`} />
          </Form>
        )}
      </div>
    </>
  )
}
