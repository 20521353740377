import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'

export default function ListarEndpoints({ api_id, permissions }) {
  const [endpoints, setEndpoints] = useState(false)

  const tagMethod = __method => {
    switch (__method) {
      case 'get':
        return 'success'
      case 'post':
        return 'warning'
      case 'put':
        return 'info'
      case 'patch':
        return 'info'
      case 'delete':
        return 'danger'
      case 'options':
        return 'dark'
      case 'head':
        return 'dark'
      default:
        return 'light'
    }
  }

  const loadEndpoints = useCallback(async () => {
    const r = await api.get(`/apis/${api_id}/endpoints?limit=9999`)
    setEndpoints(r.data)
  }, [api_id])

  useEffect(() => {
    loadEndpoints()
  }, [loadEndpoints])

  return (
    <>
      <div className="table-container">
        <table className="table is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th colSpan="2">Endpoint</th>
              <th>Permissão</th>
            </tr>
          </thead>
          <tbody>
            {!endpoints && (
              <tr>
                <td colSpan="4">
                  <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
                </td>
              </tr>
            )}
            {endpoints && (
              <>
                {endpoints.length === 0 && (
                  <tr>
                    <td colSpan="4">Nenhum endpoint adicionado</td>
                  </tr>
                )}
                {endpoints.length > 0 && (
                  <>
                    {endpoints.map((endpoint, key) => (
                      <tr
                        key={key}
                        className={`${
                          permissions &&
                          permissions.includes(endpoint.permission)
                            ? ''
                            : 'has-text-grey-light'
                        }`}
                      >
                        <td className="is-vcentered is-narrow">
                          <span
                            className={`tag is-${tagMethod(endpoint.method)}${
                              permissions &&
                              permissions.includes(endpoint.permission)
                                ? ''
                                : ' is-light'
                            }`}
                          >
                            {endpoint.method}
                          </span>
                        </td>
                        <td className="is-vcentered">{endpoint.path}</td>
                        <td className="is-narrow is-vcentered">
                          {endpoint.permission}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
