import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from '@unform/web'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import { Input, Checkbox, Submit } from '../../components/Formulario'
import Alert from '../../components/Alert'

export default function New({ session }) {
  const history = useHistory()
  const [alert, setAlert] = useState(false)

  async function saveForm(data) {
    const r = await api
      .post('/apis', {
        ...data,
        organization: session.user?.organization._id,
        public: parseInt(data.public),
        test_environment: parseInt(data.test_environment)
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">API cadastrada!</Alert>)
      history.push(`/central/apis/${r.data._id}/detalhes`)
    }
  }

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-code mr-1"></i> API's{' '}
            <i className="fas fa-angle-right mx-2"></i> Nova API
          </>
        }
      >
        <Button url={`/central/apis`} icon="arrow-left">
          Voltar
        </Button>
      </PageTitle>

      {alert}

      <div className="box mt-5">
        <Form onSubmit={saveForm}>
          <Input name="name" label="Nome" type="text" required />
          <Input name="description" label="Descrição" type="text" />
          <Input name="url" label="URL" type="text" required />
          <Input
            name="documentation_url"
            label="URL da documentação"
            type="text"
          />
          <Checkbox name="public" label="Pública" value={1} />
          <Checkbox
            name="test_environment"
            label="Ambiente de Testes"
            value={1}
          />
          <Submit cancelUrl="/central/apis" />
        </Form>
      </div>
    </>
  )
}
