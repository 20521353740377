import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonDelete } from '../../../components/ButtonDelete'

export default function RemoverDominio({ key_id, id, afterDelete }) {
  const [alert, setAlert] = useState()

  async function deleteDominio() {
    const r = await api.delete(`/chaves/${key_id}/dominios/${id}`)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">Domínio removido!</Alert>)
      afterDelete()
    } else {
      setAlert(
        <Alert color="danger">
          Não foi possível remover o Domínio.
          <br />
          Tente novamente ou entre em contato com o suporte da Central do
          Desenvolvedor.
        </Alert>
      )
    }
  }

  return (
    <>
      {alert}
      <ButtonDelete
        onClick={e => {
          e.preventDefault()
          if (window.confirm('Tem certeza que deseja remover este Domínio?'))
            deleteDominio()
        }}
        title="Remover domínio"
      ></ButtonDelete>
    </>
  )
}
