import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import api from '../services/api'
import PageTitle from '../components/PageTitle'
import FormatDate from '../components/FormatDate'

export default function Dashboard({ session }) {
  const [notificacoes, setNotificacoes] = useState(false)
  const getNotificacoes = useCallback(async () => {
    const r = await api
      .get(`/notificacoes/${session.user._id}?limit=12&page=1`)
      .then(data => data)
      .catch(err => err.response)
    if (r.status === 200) setNotificacoes(r.data)
  }, [session])

  useEffect(() => {
    getNotificacoes()
  }, [getNotificacoes])

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-columns mr-1"></i> Painel
          </>
        }
      ></PageTitle>

      <div className="mt-5">
        <div className="is-hidden-desktop is-hidden-tablet mb-5"></div>
        <p className="mb-2">
          <i className="fas fa-bell mr-1 has-text-grey-light"></i> Notificações
        </p>
        <div className="box">
          {notificacoes && notificacoes.length > 0 ? (
            notificacoes.map((notificacao, key) => (
              <>
                {key > 0 && <hr className="mt-0 mb-3" />}
                <p key={key} className="mb-3">
                  <Link to={`/central/${notificacao.url}`}>
                    {notificacao.message}
                  </Link>
                  <span className="has-text-grey-light">
                    &nbsp;em <FormatDate timestamp={notificacao.created_in} />
                  </span>
                </p>
              </>
            ))
          ) : (
            <p className="has-text-grey">Sem notificações.</p>
          )}
        </div>
      </div>
    </>
  )
}
