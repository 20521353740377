import React, { useState, useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import { DetailBox } from '../../components/DetailBox'
import ListarAdministradores from './details/ListarAdministradores'

export default function Details() {
  const { uid } = useParams()
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    async function loadData() {
      const r = await api
        .get(`/organizacoes/${uid}`)
        .then(data => data)
        .catch(error => error.response)
      if (r.status !== 200) {
        setError(r.data)
      } else {
        setData(r.data)
      }
    }
    loadData()
  }, [uid])

  return error ? (
    <>
      <Alert color="danger">{error.error.message}</Alert>
      <Redirect to="/central/organizacoes" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-building mr-1"></i>{' '}
            Organizações <i className="fas fa-angle-right mx-2"></i> Detalhes da
            Organização
          </>
        }
      >
        <Button url="/central/organizacoes" icon="arrow-left" extraClass="mr-3">
          Voltar
        </Button>
        {data && (
          <Button
            url={`/central/organizacoes/${uid}/editar`}
            icon="pen"
            extraClass="is-link has-text-dark"
          >
            Editar
          </Button>
        )}
      </PageTitle>

      <div className="mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {data && (
          <>
            <h3 className="title is-3 mt-5 mb-2">{data.name}</h3>
            {data.admin && (
              <p className="tag is-link has-text-dark">
                Administradora da Plataforma
              </p>
            )}
          </>
        )}
      </div>

      {data && (
        <DetailBox title="Administradores" icon="users" extraClass="mt-5">
          <ListarAdministradores organization_id={data._id} />
        </DetailBox>
      )}
    </>
  )
}
