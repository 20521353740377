import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { isLoggedIn } from './auth'

import Login from './pages/Login'
import Logout from './pages/Logout'

import Template from './pages/Template'

import Dashboard from './pages/Dashboard'
import SouServidor from './pages/SouServidor'
import Moderar from './pages/Moderar'
import MinhasChaves from './pages/MinhasChaves'
import Api from './pages/Api'
import Organizacao from './pages/Organizacao'
import Usuario from './pages/Usuario'
import Notificacoes from './pages/Notificacoes'
import Chaves from './pages/Chaves'
import Logs from './pages/Logs'
import MinhaOrganizacao from './pages/MinhaOrganizacao'
import Cadastro from './pages/Cadastro'

import Alert from './components/Alert'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const session = isLoggedIn()
      return session ? (
        <Template session={session}>
          <Component {...props} session={session} />
        </Template>
      ) : (
        <>
          <Alert color="danger">
            Você precisa estar logado para acessar o painel!
          </Alert>
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        </>
      )
    }}
  />
)

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const session = isLoggedIn()
      return session && session?.user.admin ? (
        <Template session={session}>
          <Component {...props} session={session} />
        </Template>
      ) : (
        <>
          <Alert color="danger">
            {session
              ? 'Somente administradores podem acessar esta página!'
              : 'Você precisa estar logado para acessar o painel!'}
          </Alert>
          <Redirect
            to={{
              pathname: session ? '/' : '/login',
              state: { from: props.location }
            }}
          />
        </>
      )
    }}
  />
)

const SuperAdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const session = isLoggedIn()
      return session && session?.user?.organization.admin ? (
        <Template session={session}>
          <Component {...props} session={session} />
        </Template>
      ) : (
        <>
          <Alert color="danger">
            {session
              ? 'Somente administradores da plataforma podem acessar esta página!'
              : 'Você precisa estar logado para acessar o painel!'}
          </Alert>
          <Redirect
            to={{
              pathname: session ? '/' : '/login',
              state: { from: props.location }
            }}
          />
        </>
      )
    }}
  />
)

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Rotas Públicas */}
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/cadastre-se" component={Cadastro} />
        <Route exact path="/logout" component={Logout} />

        {/* Rotas para desenvolvedores */}
        <PrivateRoute exact path="/central" component={Dashboard} />
        <PrivateRoute
          exact
          path="/central/notificacoes"
          component={Notificacoes}
        />
        <PrivateRoute
          exact
          path="/central/minhas-chaves"
          component={MinhasChaves.list}
        />
        <PrivateRoute
          exact
          path="/central/minhas-chaves/nova"
          component={MinhasChaves.new}
        />
        <PrivateRoute
          exact
          path="/central/minhas-chaves/:id/detalhes"
          component={MinhasChaves.details}
        />
        <PrivateRoute
          path="/central/minhas-chaves/:id/editar"
          component={MinhasChaves.update}
        />

        {/* Rota para Desenvolvedores externos */}
        <PrivateRoute
          exact
          path="/central/sou-servidor"
          component={SouServidor}
        />

        {/* Rotas para Administradores */}
        <AdminRoute exact path="/central/moderar" component={Moderar.list} />
        <AdminRoute
          exact
          path="/central/moderar/:id"
          component={Moderar.details}
        />

        <AdminRoute exact path="/central/apis" component={Api.list} />
        <AdminRoute
          exact
          path="/central/apis/:id/detalhes"
          component={Api.details}
        />
        <AdminRoute exact path="/central/apis/nova" component={Api.new} />
        <AdminRoute
          exact
          path="/central/apis/:id/editar"
          component={Api.update}
        />

        <AdminRoute exact path="/central/chaves" component={Chaves.list} />
        <AdminRoute
          exact
          path="/central/chaves/:id/detalhes"
          component={Chaves.details}
        />

        <AdminRoute exact path="/central/logs" component={Logs.list} />
        <AdminRoute
          exact
          path="/central/logs/:id/detalhes"
          component={Logs.details}
        />

        <AdminRoute
          exact
          path="/central/minha-organizacao"
          component={MinhaOrganizacao.details}
        />
        <AdminRoute
          exact
          path="/central/minha-organizacao/editar"
          component={MinhaOrganizacao.update}
        />

        {/* Rotas para Super Administradores */}
        <SuperAdminRoute
          exact
          path="/central/organizacoes"
          component={Organizacao.list}
        />
        <SuperAdminRoute
          exact
          path="/central/organizacoes/novo"
          component={Organizacao.new}
        />
        <SuperAdminRoute
          exact
          path="/central/organizacoes/:uid/detalhes"
          component={Organizacao.details}
        />
        <SuperAdminRoute
          path="/central/organizacoes/:uid/editar"
          component={Organizacao.update}
        />

        <SuperAdminRoute
          exact
          path="/central/usuarios"
          component={Usuario.list}
        />
        <SuperAdminRoute
          exact
          path="/central/usuarios/:uid/detalhes"
          component={Usuario.details}
        />
        <SuperAdminRoute
          path="/central/usuarios/:uid/editar"
          component={Usuario.update}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
