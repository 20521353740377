import React from 'react'

export const DetailBox = React.memo(function ({
  extraClass = '',
  show = true,
  title,
  titleSize = 5,
  icon = false,
  iconType = 'fas',
  description = false,
  spacing = 3,
  children
}) {
  if (!show) return
  return (
    <div className={`box ${extraClass}`}>
      <h2 className={`is-size-${titleSize} mb-${spacing}`}>
        {icon && <i className={`${iconType} fa-${icon} mr-2`}></i>}
        {title}
      </h2>
      {description && <p className={`mb-${spacing}`}>{description}</p>}
      {children}
    </div>
  )
})
