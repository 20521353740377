import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import AdicionarEscopo from './AdicionarEscopo'
import ApagarEscopo from './ApagarEscopo'

export default function ListarEscopos({ api_id }) {
  const [escopos, setEscopos] = useState(false)

  const loadEscopos = useCallback(async () => {
    const r = await api.get(`/apis/${api_id}/escopos`)
    setEscopos(r.data)
  }, [api_id])

  useEffect(() => {
    loadEscopos()
  }, [loadEscopos])

  return (
    <>
      <AdicionarEscopo api_id={api_id} afterCreate={loadEscopos} />
      <table className="table is-fullwidth mt-3">
        <thead>
          <tr>
            <th className="has-text-centered has-text-grey-light is-narrow">
              <i className="fas fa-trash"></i>
            </th>
            <th>Organização</th>
          </tr>
        </thead>
        <tbody>
          {!escopos && (
            <tr>
              <td colSpan="2">
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {escopos && (
            <>
              {escopos.length === 0 && (
                <tr>
                  <td colSpan="2">Nenhuma permissão adicionada</td>
                </tr>
              )}
              {escopos.length > 0 && (
                <>
                  {escopos.map((escopo, key) => (
                    <tr key={key}>
                      <td className="is-vcentered">
                        <ApagarEscopo
                          api_id={api_id}
                          scope_id={escopo._id}
                          afterDelete={loadEscopos}
                        />
                      </td>
                      <td className="is-vcentered">{escopo.name}</td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
