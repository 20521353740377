import React, { useEffect, createRef } from 'react'
import { useField } from '@unform/core'

export default function Checkbox({ name, label, ...rest }) {
  const inputRef = createRef()
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue(ref) {
        if (!ref.checked) return null
        if (ref.value) {
          return ref.value
        } else {
          return 1
        }
      }
    })
  }, [fieldName, inputRef, registerField])

  return (
    <div className="field mb-5">
      <label className="" htmlFor={name}>
        <input
          id={name}
          name={name}
          ref={inputRef}
          className="is-checkradio mr-2"
          type="checkbox"
          {...rest}
        />
        {label}
      </label>
    </div>
  )
}
