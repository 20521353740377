import servidor_api from '../services/servidor_api'
import organograma_api from '../services/organograma_api'
import CPF from 'cpf-check'

/**
 * Verifica se um usuário é servidor público com matricula ativo
 * @param {string} cpf [CPF do usuário da Central]
 * @returns {array | boolean} [Lista de matriculas ou falso]
 */
export async function hasMatriculaAtiva(cpf) {
  if (!cpf || !CPF.validate(cpf)) return false

  const matriculas = await servidor_api
    .get(`?cpf=${cpf}&apenas_ativos=true`)
    .then(r => r.data.results)
    .catch(e => false)
  if (!matriculas) return false

  return matriculas
}

/**
 * Verifica se o servidor possui local de trabalho padrão
 * @param {[object]} matriculas [Lista de matrículas ativas do Servidor]
 * @returns {array | boolean} [Lista de departamentos ou falso]
 */
export function hasLocalTrabalho(matriculas) {
  if (!matriculas || (Array.isArray(matriculas) && matriculas.length < 1))
    return false

  const locaisTrabalho = matriculas.filter(({ departamentos }) =>
    departamentos.filter(({ padrao }) => (padrao ? true : false))
  )

  if (
    !locaisTrabalho ||
    (Array.isArray(locaisTrabalho) && locaisTrabalho.length < 1)
  )
    return false

  return locaisTrabalho.map(v => v.departamentos).flat()
}

/**
 * Verifica se um usuário tem alguma das funções náo gratificadas de Programador
 * @param {[object]} matriculas [Lista de matrículas ativas do Servidor]
 * @param {[string]} fngs [Lista de nomes de funções não gratificadas aceitas]
 * @returns {boolean}
 */
export function hasFNG(
  matriculas,
  allowed = [
    'programador',
    'desenvolvedor',
    'desenvolvedor de software',
    'analista de sistemas',
    'engenheiro de software'
  ]
) {
  if (!matriculas || (Array.isArray(matriculas) && matriculas.length < 1))
    return false

  const fngs = []
  matriculas.map(({ funcoes_nao_gratificadas }) =>
    funcoes_nao_gratificadas.map(v => fngs.push(v))
  )
  if (!fngs || (Array.isArray(fngs) && fngs.length < 1)) return false

  const fng = fngs.filter(({ nome }) => allowed.includes(nome.toLowerCase()))
  if (!fng || (Array.isArray(fng) && fng.length < 1)) return false

  return true
}

/**
 * Consulta unidade gestora do local de trabalho do servidor
 * @param {[object]} departamentos [Lista de Departamentos que são Local de Trabalho padrão]
 * @returns {[object] | boolean} [Lista com unidades gestoras ou falso]
 */
export async function getUnidadeGestora(departamentos) {
  if (
    !departamentos ||
    (Array.isArray(departamentos) && departamentos.length < 1)
  )
    return false

  const unidades = []

  departamentos.map(
    async departamento =>
      await organograma_api
        .get(`/departamentos/${departamento.id}/caminho`)
        .then(r =>
          r.data.map(entidade => {
            if (
              entidade.__tipo === 'unidade' &&
              entidade.gestora &&
              entidade.unidade_id === null
            ) {
              const unidade = { id: entidade.id, sigla: entidade.sigla }
              if (!unidades.includes(unidade)) unidades.push(unidade)
              return true
            }
            return false
          })
        )
  )

  return unidades
}
