import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonAdd } from '../../../components/ButtonAdd'

export default function AdicionarEndpoint({
  api_id,
  afterCreate,
  permissions = []
}) {
  const [alert, setAlert] = useState()
  const [path, setPath] = useState('')
  const methods = [
    'get',
    'post',
    'put',
    'patch',
    'delete',
    'trace',
    'connect',
    'options',
    'head'
  ]
  const [method, setMethod] = useState('')
  const [permission, setPermission] = useState('')

  async function saveEndpoint(e) {
    e.preventDefault()
    const button = e.target
    button.disabled = true

    const r = await api
      .post(`/apis/${api_id}/endpoints`, {
        path,
        method,
        permission
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Endpoint cadastrado!</Alert>)
      setPath('')
      afterCreate()
    }
    button.disabled = false
  }

  return (
    <>
      {alert}
      <p className="mb-3">
        O endpoint deve ser cadastrado de acordo com as configurações de rotas
        de sua API. Exemplo: <code>/path/to/endpoint</code>. Para os casos com
        parâmetros, utilize <code>:param</code> para definir o segmento que
        levará o parâmetro. Exemplo:{' '}
        <code>/path/:param/to/:param/endpoint</code>.
      </p>
      <div className="py-3">
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  id="path-endpoint"
                  type="text"
                  className="input"
                  placeholder="Path"
                  value={path}
                  onChange={e => setPath(e.target.value)}
                />
              </div>
            </div>
            <div className="field is-narrow">
              <div className="control">
                <div className="select">
                  <select
                    id="method-endpoint"
                    defaultValue={''}
                    onChange={e => setMethod(e.target.value)}
                  >
                    <option value="">Método</option>
                    {methods &&
                      methods.map(method => (
                        <option key={method} value={method}>
                          {method}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="field is-narrow">
              <div className="control">
                <div className="select">
                  <select
                    id="permission-endpoint"
                    defaultValue={''}
                    onChange={e => setPermission(e.target.value)}
                  >
                    <option value="">Permissão</option>
                    {permissions &&
                      permissions.map(permission => (
                        <option key={permission._id} value={permission.name}>
                          {permission.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <ButtonAdd small={false} onClick={e => saveEndpoint(e)}></ButtonAdd>
          </div>
        </div>
      </div>
    </>
  )
}
