import React, { useEffect, createRef } from 'react'
import { useField } from '@unform/core'
import NumberFormat from 'react-number-format'

export default function Input({
  name,
  label,
  hint = false,
  type = 'text',
  required = false,
  mb = 5,
  ...rest
}) {
  const inputRef = createRef()
  const { fieldName, defaultValue = '', registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'state.value'
    })
  }, [fieldName, inputRef, registerField])

  return (
    <div className={`field mb-${mb}`}>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
          {required ? <small className="has-text-grey">*</small> : ''}
        </label>
      )}
      <div className="control">
        <NumberFormat
          id={name}
          name={name}
          type={type}
          ref={inputRef}
          required={required}
          defaultValue={defaultValue}
          className={'input' + (error ? ' is-danger' : '')}
          {...rest}
        />
        {hint && (
          <p className="hint has-text-grey">
            <em>{hint}</em>
          </p>
        )}
      </div>
      {error && <p className="help is-danger">{error}</p>}
    </div>
  )
}
