import React, { useEffect, createRef } from 'react'
import { useField } from '@unform/core'
import AsyncSelect from 'react-select/async'

export default function LiveSearch({
  name,
  label,
  options,
  children = false,
  required = false,
  mb = 5,
  ...rest
}) {
  const inputRef = createRef()
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return []
          }
          return ref.select.state.value.map(option => option.value)
        }
        if (!ref.select.state.value) {
          return ''
        }
        return ref.select.state.value.value
      }
    })
  }, [fieldName, inputRef, registerField, rest.isMulti])

  return (
    <div className={`field mb-${mb}`}>
      {label && (
        <label htmlFor={name} className="label">
          {label}
          {required ? <small className="has-text-grey">*</small> : ''}
        </label>
      )}
      <div className="control">
        <div className="is-fullwidth">
          <AsyncSelect
            id={name}
            name={name}
            ref={inputRef}
            required={required}
            placeholder="Selecione..."
            className="has-text-dark"
            {...rest}
          />
        </div>
      </div>
    </div>
  )
}
