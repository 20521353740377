import React from 'react'

export function ButtonDelete({ extraClass, small = true, children, ...props }) {
  return (
    <div className="field is-narrow">
      <div className="control">
        <button
          type="button"
          className={`button py-0 px-2 ${
            small ? 'is-small' : ''
          } is-danger ${extraClass}`}
          {...props}
        >
          <i className={`fas fa-times ${children ? 'mr-2' : ''}`}></i>
          {children && (
            <span className="is-uppercase has-text-weight-bold">
              {children}
            </span>
          )}
        </button>
      </div>
    </div>
  )
}
