import React, { useEffect, createRef } from 'react'
import { useField } from '@unform/core'

export default function Input({
  name,
  label,
  hint = false,
  type = 'text',
  size = 'normal',
  required = false,
  disabled = false,
  icon = false,
  mb = 5,
  ...rest
}) {
  const inputRef = createRef()
  const { fieldName, defaultValue = '', registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, inputRef, registerField])

  return type === 'hidden' ? (
    <input
      id={name}
      name={name}
      type={type}
      ref={inputRef}
      required={required}
      defaultValue={defaultValue}
      {...rest}
    />
  ) : (
    <div className={`field mb-${mb}`}>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
          {required || disabled ? (
            ''
          ) : (
            <small className="has-text-grey-light ml-2">-- opcional</small>
          )}
        </label>
      )}
      <div className={`control${icon ? ' has-icons-left' : ''}`}>
        <input
          id={name}
          name={name}
          type={type}
          ref={inputRef}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          className={`input ${error ? ' is-danger' : ''} is-${size}`}
          {...rest}
        />
        {icon && (
          <span className="icon is-small is-left">
            <i className={`fas fa-${icon}`}></i>
          </span>
        )}
        {hint && <p className="hint has-text-grey-light mt-1">{hint}</p>}
      </div>
      {error && <p className="help is-danger">{error}</p>}
    </div>
  )
}
