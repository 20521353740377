import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { Form } from '@unform/web'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import { Input, Submit } from '../../components/Formulario'
import Alert from '../../components/Alert'

export default function Update({ session }) {
  const formRef = useRef()
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const [alert, setAlert] = useState(false)
  const { organization } = session.user

  const loadData = useCallback(async () => {
    const r = await api
      .get(`/organizacoes/${organization._id}`)
      .then(data => data)
      .catch(error => error.response)
    if (r.status !== 200) {
      setError(r.data)
    } else {
      setData(r.data)
    }
  }, [organization._id])

  useEffect(() => {
    loadData()
  }, [loadData])

  async function saveForm(data) {
    const r = await api
      .put(`/organizacoes/${organization._id}`, {
        ...data,
        admin: false
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Organização atualizada!</Alert>)
    }
  }

  return error ? (
    <>
      <Alert color="danger">{error.error.message}</Alert>
      <Redirect to="/central" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-users mr-1"></i> Minha
            Organização <i className="fas fa-angle-right mx-2"></i> Editar
            Organização
          </>
        }
      >
        <Button url={`/central/minha-organizacao`} icon="arrow-left">
          Voltar
        </Button>
      </PageTitle>

      <div className="box mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {alert}
        {data && (
          <Form ref={formRef} initialData={data} onSubmit={saveForm}>
            <Input name="name" label="Nome" type="text" />
            <Submit cancelUrl={`/central/minha-organizacao`} />
          </Form>
        )}
      </div>
    </>
  )
}
