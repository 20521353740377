import React, { useRef, useState, useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { Form } from '@unform/web'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import { Input, Checkbox, Submit } from '../../components/Formulario'
import Alert from '../../components/Alert'

export default function Update({ session }) {
  const { id } = useParams()
  const formRef = useRef()
  const history = useHistory()
  const [data, setData] = useState(false)
  const [alert, setAlert] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    async function loadData() {
      const r = await api
        .get(`/apis/${id}`)
        .then(data => data)
        .catch(error => error.response)
      if (r.status !== 200) {
        setError(r.data)
      } else {
        setData(r.data)
      }
    }
    loadData()
  }, [id])

  async function saveForm(data) {
    const r = await api
      .put(`/apis/${id}`, {
        ...data,
        public: parseInt(data.public),
        production: parseInt(data.production)
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">API atualizada!</Alert>)
    }
  }

  async function deleteApi() {
    const r = await api.delete(`/apis/${id}`)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">API apagada!</Alert>)
      history.push('/central/apis')
    } else {
      setAlert(
        <Alert color="danger">
          Não foi possível excluir a API.
          <br />
          Tente novamente ou entre em contato com o suporte da Central do
          Desenvolvedor.
        </Alert>
      )
    }
  }

  return error ? (
    <>
      <Alert color="danger">{error.error.message}</Alert>
      <Redirect to="/central/apis" />
    </>
  ) : data && data.organization._id !== session.user?.organization._id ? (
    <>
      <Alert color="danger">Esta API não pertence a sua organização!</Alert>
      <Redirect to="/central/apis" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-code mr-1"></i> API's{' '}
            <i className="fas fa-angle-right mx-2"></i> Editar API
          </>
        }
      >
        <Button url={`/central/apis/${id}/detalhes`} icon="arrow-left">
          Voltar
        </Button>
        <Button
          url="#delete"
          onClick={e => {
            e.preventDefault()
            if (
              window.confirm(
                `Tem certeza que deseja apagar a API ${data.name}? Ao apagá-la, você também irá apagar todas as chaves desta API.`
              )
            )
              deleteApi()
          }}
          icon="trash"
          extraClass="is-danger"
        >
          Apagar
        </Button>
      </PageTitle>

      <div className="box mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {alert}
        {data && (
          <Form ref={formRef} initialData={data} onSubmit={saveForm}>
            <Input name="name" label="Nome" type="text" required />
            <Input name="description" label="Descrição" type="text" />
            <Input name="url" label="URL" type="text" required />
            <Input
              name="documentation_url"
              label="URL da documentação"
              type="text"
            />
            <Checkbox
              name="public"
              label="Pública"
              value={1}
              defaultChecked={data.public ? true : false}
            />
            <Checkbox
              name="test_environment"
              label="Ambiente de Testes"
              value={1}
              defaultChecked={data.test_environment ? true : false}
            />
            <Submit
              cancelUrl={`/central/apis/${id}/detalhes`}
              disabled={formRef.current?.getData() === data ? true : false}
            />
          </Form>
        )}
      </div>
    </>
  )
}
