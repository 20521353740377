import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import FormatDate from '../../components/FormatDate'

export default function List({ session }) {
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const limit = 10
  const [endPage, setEndPage] = useState(false)
  const { organization } = session.user

  useEffect(() => {
    async function loadList() {
      const r = await api.get(
        `/chaves/pendentes/${organization._id}?limit=${limit}&page=${page}`
      )
      if (!r.data || (Array.isArray(r.data) && r.data.length < limit))
        setEndPage(true)
      setList(previousList => previousList.concat(r.data))
    }
    loadList()
  }, [limit, page, organization._id])

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-tasks mr-1"></i> Moderar
            Chaves
          </>
        }
      ></PageTitle>

      <div className="box mt-5">
        {!list && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {Array.isArray(list) && (
          <>
            <table className="table is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th colSpan={2}>Api</th>
                  <th>Ambiente</th>
                  <th>Data do Pedido</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan="4">
                      Não há chaves pendentes de moderação nesta Organização
                    </td>
                  </tr>
                )}
                {list.length > 0 && (
                  <>
                    {list.map((registro, key) => (
                      <tr key={key}>
                        <td className="is-narrow is-vcentered">
                          <Link
                            to={`/central/moderar/${registro._id}`}
                            className="button is-small"
                          >
                            <i className="fas fa-search mr-1"></i>
                          </Link>
                        </td>
                        <td className="is-vcentered">
                          API: {registro.api.name}
                          <i
                            className={`fas fa-${
                              registro.public ? 'globe' : 'lock'
                            } has-text-grey-light ml-2`}
                            title={
                              registro.public ? 'API Pública' : 'API Privada'
                            }
                          ></i>
                          <div className="mt-2">
                            Objetivo da chave:{' '}
                            <span className="has-text-grey-lighter">
                              {registro.reason}
                            </span>
                          </div>
                        </td>
                        <td className="is-narrow is-vcentered">
                          <span
                            className={`tag is-${
                              registro.test_environment ? 'danger' : 'info'
                            }`}
                          >
                            {registro.test_environment ? 'Testes' : 'Produção'}
                          </span>
                        </td>
                        <td className="is-narrow is-vcentered has-text-grey-lighter">
                          <FormatDate timestamp={registro.created_in} />
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            {!endPage && (
              <Button
                onClick={() => setPage(page + 1)}
                icon="sync"
                extraClass="is-dark"
              >
                Carregar mais
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}
