import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from '@unform/web'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import { Input, LiveSearch, Submit } from '../../components/Formulario'
import Alert from '../../components/Alert'

export default function New({ session }) {
  const history = useHistory()
  const [alert, setAlert] = useState(false)
  const { user: { organization } = {} } = session

  const getApis = useCallback(
    async search => {
      if (search.length > 0 && search.length < 3) return
      const r = await api.get(
        `/apis/${
          organization ? `?scope=${organization._id}&` : 'publicas?'
        }${`search=${search}`}`
      )
      return r.data.map(api => ({
        value: api._id,
        label: api.name + ' - ' + api.url
      }))
    },
    [organization]
  )

  async function saveForm(data) {
    const {
      user: { _id: user }
    } = session
    const r = await api
      .post('/chaves', {
        ...data,
        users: [user]
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Chave cadastrada!</Alert>)
      history.push(`/central/minhas-chaves/${r.data._id}/detalhes`)
    }
  }

  return (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-key mr-2"></i> Minhas
            Chaves <i className="fas fa-angle-right mx-2"></i> Nova Chave
          </>
        }
      >
        <Button url={`/central/minhas-chaves`} icon="arrow-left">
          Voltar
        </Button>
      </PageTitle>

      {alert}

      <div className="box mt-5">
        <Form onSubmit={saveForm}>
          <LiveSearch
            name="api"
            label="API"
            cacheOptions
            defaultOptions
            loadOptions={getApis}
          />
          <Input
            name="reason"
            label="Objetivo"
            hint="Explique seu objetivo com o uso desta chave. Muito importante para requisição de permissões."
            type="text"
            required
          />
          <Submit cancelUrl="/central/minhas-chaves" />
        </Form>
      </div>
    </>
  )
}
