import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import AdicionarDominio from './AdicionarDominio'
import RemoverDominio from './RemoverDominio'

export default function ListarDominios({
  key_id,
  api_id,
  disabled,
  updateDomains
}) {
  const [dominios, setDominios] = useState(false)

  const loadDominios = useCallback(async () => {
    const r = await api.get(`/chaves/${key_id}/dominios`)
    setDominios(r.data)
    if (r.data.length > 0)
      updateDomains(
        r.data.map(({ url }) => {
          return url
        })
      )
    else updateDomains([])
  }, [key_id, updateDomains])

  useEffect(() => {
    loadDominios()
  }, [loadDominios])

  return (
    <>
      {!disabled && (
        <AdicionarDominio
          key_id={key_id}
          api_id={api_id}
          afterCreate={() => loadDominios()}
        />
      )}
      <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th colSpan={`${!disabled ? 2 : 1}`}>Dominio</th>
          </tr>
        </thead>
        <tbody>
          {!dominios && (
            <tr>
              <td colSpan={disabled ? 1 : 2}>
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {dominios && (
            <>
              {dominios.length === 0 && (
                <tr>
                  <td colSpan={disabled ? 1 : 2}>Nenhum domínio adicionado</td>
                </tr>
              )}
              {dominios.length > 0 && (
                <>
                  {dominios.map((dominio, key) => (
                    <tr key={key}>
                      {!disabled && (
                        <td className="is-vcentered is-narrow">
                          <RemoverDominio
                            key_id={key_id}
                            id={dominio._id}
                            afterDelete={() => loadDominios()}
                          />
                        </td>
                      )}
                      <td className="is-vcentered">{dominio.url}</td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
