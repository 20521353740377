import React, { useState, useEffect, useCallback } from 'react'
import { Link, useParams, Redirect } from 'react-router-dom'

import api from '../../services/api'
import servidor_api from '../../services/servidor_api'
import PageTitle from '../../components/PageTitle'
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import FormatDate from '../../components/FormatDate'

import CPF from 'cpf-check'

export default function Details() {
  const { uid } = useParams()
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const [chaves, setChaves] = useState(false)
  const [matriculas, setMatriculas] = useState(false)

  const getUser = useCallback(async () => {
    const r = await api
      .get(`/usuarios/${uid}`)
      .then(data => data)
      .catch(err => err.response)
    if (r.status !== 200) {
      setError(r.data)
    } else {
      setData(r.data)
    }
  }, [uid])

  const getKeys = useCallback(async () => {
    const r = await api
      .get(`/chaves/?user=${uid}`)
      .then(data => data)
      .catch(err => err.response)
    if (r.status !== 200) {
      setError(r.data)
    } else {
      setChaves(r.data)
    }
  }, [uid])

  useEffect(() => {
    getUser()
    getKeys()
  }, [getUser, getKeys])

  const getMatriculas = useCallback(async () => {
    if (!data) return
    const r = await servidor_api
      .get(`?cpf=${data.cpf}&apenas_ativos=true`)
      .then(res => res)
      .catch(err => err.response)
    if (r?.status === 200) {
      setMatriculas(r.data.results)
    } else {
      setError({
        error: { message: 'Não foi possível carregar os detalhes do usuário.' }
      })
    }
  }, [data])

  useEffect(() => {
    getMatriculas()
  }, [getMatriculas])

  return error ? (
    <>
      <Alert color="danger">{error.error.message}</Alert>
      <Redirect to="/central/usuarios" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-users mr-1"></i> Usuários{' '}
            <i className="fas fa-angle-right mx-2"></i> Detalhes do Usuário
          </>
        }
      >
        <Button url="/central/usuarios" icon="arrow-left" extraClass="mr-3">
          Voltar
        </Button>
        {data && (
          <Button
            url={`/central/usuarios/${uid}/editar`}
            icon="pen"
            extraClass="is-link has-text-dark"
          >
            Editar
          </Button>
        )}
      </PageTitle>

      <div className="mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {data && (
          <>
            <h3 className="title is-3 mb-2">{data.name}</h3>
            {data.admin && <p className="tag is-primary">Administrador</p>}

            <div className="box mt-5">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>CPF</th>
                    <th>E-mail</th>
                    {data.organization && (
                      <th className="is-narrow">Organização</th>
                    )}
                    <th>Externo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="is-narrow">{CPF.format(data.cpf)}</td>
                    <td>{data.email}</td>
                    {data.organization && <td>{data.organization.name}</td>}
                    <td className="is-narrow">
                      {data.public ? 'Sim' : 'Não, servidor público'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {!data.public && (
              <>
                <h2 className="title is-5 mb-5">
                  <i className="fas fa-address-card has-text-grey-light mr-2"></i>{' '}
                  Vínculos com o Governo
                </h2>
                <div className="box">
                  <table className="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Matrícula</th>
                        <th>Local de Trabalho</th>
                      </tr>
                    </thead>
                    <tbody>
                      {matriculas &&
                        matriculas.map(matricula =>
                          matricula.departamentos.map(departamento => (
                            <tr key={`${matricula.id}-${departamento.id}`}>
                              <td className="is-narrow is-vcentered">
                                {matricula.matricula}
                              </td>
                              <td>
                                <span className="has-text-grey-lighter mr-1">
                                  Departamento:
                                </span>
                                {`${departamento.nome}`}
                                <div>
                                  <span className="has-text-grey-lighter mr-1">
                                    Unidade:
                                  </span>
                                  {`${departamento.unidade.sigla} - ${departamento.unidade.nome}`}
                                </div>
                              </td>
                            </tr>
                          ))
                        )}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            {Array.isArray(chaves) && chaves.length > 0 && (
              <>
                <h2 className="title is-5 mb-5">
                  <i className="fas fa-key has-text-grey-light mr-2"></i> Chaves
                </h2>
                <div className="box">
                  <table className="table is-fullwidth is-hoverable">
                    <thead>
                      <tr>
                        <th>API</th>
                        <th>Chave</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chaves.map((registro, key) => (
                        <tr key={key}>
                          <td className="is-vcentered">
                            {registro.disabled ? (
                              <div>
                                <span className="tag is-danger">
                                  Desativada
                                </span>
                              </div>
                            ) : (
                              ''
                            )}
                            <p className="m-0 has-text-grey-lighter">
                              <i className="fas fa-clock mr-2"></i>Criada em{' '}
                              <FormatDate timestamp={registro.created_in} />
                            </p>
                            <Link
                              to={`/central/chaves/${registro._id}/detalhes`}
                              className={`is-block is-size-4 has-text-weight-bold ${
                                registro.disabled ? 'has-text-danger' : ''
                              }`}
                            >
                              {registro._id}
                            </Link>
                            <p className="m-0">{registro.reason}</p>
                            <p className="m-0 mt-2 has-text-grey-lighter">
                              {registro.domains ? (
                                registro.domains.map((domain, domainKey) => (
                                  <span
                                    className="tag is-dark mr-3"
                                    key={domainKey}
                                  >
                                    <i className="fas fa-globe mr-2"></i>
                                    {domain.url}
                                  </span>
                                ))
                              ) : (
                                <span className="has-text-danger">
                                  <i className="fas fa-globe mr-2"></i> Sem
                                  domínios configurados
                                </span>
                              )}
                            </p>
                          </td>
                          <td className="is-vcentered is-narrow">
                            <p className="title is-5 has-text-weight-bold m-0">
                              {registro.api.name}
                            </p>
                            <p className="has-text-grey-lighter my-2">
                              {registro.api.url}
                            </p>
                            <p className="m-0">
                              <span
                                className={`tag is-${
                                  registro.test_environment ? 'danger' : 'info'
                                }`}
                              >
                                Ambiente de{' '}
                                {registro.test_environment
                                  ? 'Testes'
                                  : 'Produção'}
                              </span>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}
