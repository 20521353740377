import React from 'react'

export default function PageTitle({ title, children }) {
  return (
    <div>
      <h1 className="page-title is-size-4 mb-0 has-text-weight-bold">
        {title}
      </h1>
      {children && <div className="mt-3">{children}</div>}
    </div>
  )
}
