import React from 'react'
import Routes from './routes'

import 'bulma/css/bulma.css'
import './assets/darkly.css'
import './assets/site.css'
import './assets/login.css'
import './assets/app.css'

const App = () => <Routes />

export default App
