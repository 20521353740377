import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonAdd } from '../../../components/ButtonAdd'

export default function AdicionarPermissao({ api_id, afterCreate }) {
  const [alert, setAlert] = useState()
  const [name, setName] = useState('')
  const [moderate, setModerate] = useState(false)

  async function savePermissao(e) {
    e.preventDefault()
    const button = e.target
    button.disabled = true
    if (name.length < 3) return false
    const r = await api
      .post(`/apis/${api_id}/permissoes`, {
        api_id,
        name,
        moderate: moderate ? 1 : null
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Permissão cadastrada!</Alert>)
      setName('')
      setModerate(false)
      afterCreate()
    }
    button.disabled = false
  }

  return (
    <>
      {alert}
      <div className="py-3">
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input
                  id="nome-permissao"
                  type="text"
                  className="input"
                  placeholder="Nome"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="field is-narrow">
              <div className="control">
                <label htmlFor="requer-moderacao" className="checkbox pt-2">
                  <input
                    id="requer-moderacao"
                    className="mr-2"
                    type="checkbox"
                    value={1}
                    checked={moderate}
                    onChange={e => setModerate(e.target.checked)}
                  />
                  Moderada?
                </label>
              </div>
            </div>
            <ButtonAdd small={false} onClick={e => savePermissao(e)} />
          </div>
        </div>
      </div>
    </>
  )
}
