import React, {useRef, useState} from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Form } from '@unform/web'
import CPF from 'cpf-check'
import ReCAPTCHA from "react-google-recaptcha";
import api from '../services/api'
import servidor_api from '../services/servidor_api'
import organograma_api from '../services/organograma_api'
import Logo from '../assets/logo-negativa.png'
import LogoGoverno from '../assets/logo-governo.png'
import Button from '../components/Button'
import Alert from '../components/Alert'
import { Input, Select } from '../components/Formulario'

export default function Cadastro() {

  const formRef = useRef()
  const recaptchaRef = React.createRef()
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const [alert, setAlert] = useState(false)
  const [cpf, setCpf] = useState(null)
  const [name, setName] = useState(null)
  const [organizations, setOrganizations] = useState(false)
  const [continuar, setContinuar] = useState(false)
  const [login, setLogin] = useState(false)

  const [captchaToken, setCaptchaToken] = useState()
  const [captchaSolved, setCaptchaSolved] = useState(false)

  // Consulta detalhes da unidade no Organograma
  async function getUnidadeOrganograma(unidade_id) {
    if (!unidade_id) return {}
    const r = await organograma_api
      .get(`/unidades/${unidade_id}/detalhes`)
      .then(res => res)
      .catch(err => err.response)
    if (r?.status === 200) {
      return r.data
    } else {
      return {}
    }
  }

  // Valida se um usuário já está cadastrado
  const isUser = async __cpf => {
    const r = await api
      .get(`/cadastrado/${CPF.strip(__cpf)}?captcha=${captchaToken}`, { validateStatus: (status) => (status >= 200 && status < 300) || status === 404})
      .then(data => data)
      .catch(err => err.response)
    return r.status === 200
  }

  // Valida se o usuário é um Servidor Público
  const isServidor = async __cpf => {
    const r = await servidor_api
      .get(`?cpf=${CPF.strip(__cpf)}&apenas_ativos=true`)
      .then(res => res)
      .catch(err => err.response)
    // Se a requisição não
    if (
      !r?.status ||
      r?.status !== 200 ||
      r.data.total < 1 ||
      'results' in r.data === false
    )
      return false
    const matriculas = r.data.results
    // Se não encontrar matrículas, não é servidor
    if (!matriculas || matriculas.length < 1) return false
    // Identifica departamentos padrões
    let departamentosPadrao = []
    matriculas.map(matricula =>
      departamentosPadrao.push(
        matricula.departamentos.filter(
          departamento => departamento.padrao === 1
        )
      )
    )
    // Achata array aninhado
    departamentosPadrao = departamentosPadrao.flat()
    // Se não houver um departamento padrão, não será cadastrado como servidor
    // TODO: Especificar feedback
    if (departamentosPadrao.length < 1) return false
    // Percorre lista de departamentos e adiciona suas unidades na lista
    const unidades = []
    departamentosPadrao.map(dp => unidades.push(dp.unidade))
    // Define nome do usuário de acordo com seu cadastro de Servidor
    setName(
      matriculas[0].pessoa.nome.trim()
        .toLowerCase()
        .split(' ')
        .map(w => w[0].toUpperCase() + w.substr(1))
        .join(' ')
    )
    // Ordena lista de unidades se um usuário tiver mais de um local de trabalho
    unidades.sort((a, b) =>
      a.sigla.toUpperCase() < b.sigla.toUpperCase()
        ? -1
        : a.sigla.toUpperCase() > b.sigla.toUpperCase()
        ? 1
        : 0
    )
    setOrganizations(unidades)
    return true
  }

  const saveUser = async data => {
    const { organization } = data
    if (organization) {
      const org = JSON.parse(organization)
      await api.get(`/organizacoes/nome/${org.sigla}`).then(async response => {
        if (response.status === 200 && response.data) {
          data.organization = response.data._id
        } else if (response.status === 200 && response.data === false) {
          await api
            .post(`/organizacoes`, {
              name: org.name,
              organograma: await getUnidadeOrganograma(org.id)
            })
            .then(res => {
              data.organization = res.data._id
            })
        }
      })
    }

    const r = await api
      .post('/usuarios', {
        ...data,
        admin: false,
        public: data.organization ? false : true
      })
      .then(data => data)
      .catch(error => error.response)
    if (r.status !== 200) {
      setAlert(<Alert color="danger">{r.data.error.message}</Alert>)
    } else {
      setAlert(
        <Alert color="success">
          Bem-vindo(a)! Você já pode acessar a plataforma através do formulário
          abaixo.
        </Alert>
      )
      setLogin(true)
    }
  }

  return login ? (
    <Redirect to="/login" />
  ) : (
    <div id="login" className="columns is-vcentered is-centered">
      {alert}
      <div className="column is-four-fifths is-one-third-table is-one-third-desktop is-one-third-widescreen">
        <div className="p-5 has-text-centered">
          <div>
            <img
              src={LogoGoverno}
              alt="Governo de Rondônia"
              style={{ maxWidth: 200 }}
            />
          </div>
          <img src={Logo} alt="Central do Desenvolvedor" />
        </div>
        <div className="card my-3 has-background-white is-borderless">
          <div className="card-content">
            <div className="content">
              {continuar ? (
                <>
                  <Form
                    onSubmit={saveUser}
                    ref={formRef}
                    initialData={{ cpf, name }}
                    autoComplete="new-password"
                  >
                    <Input type="hidden" name="cpf" required />
                    {organizations ? (
                      <>
                        <h4 className="has-text-grey is-uppercase mb-2">
                          Servidor Público
                        </h4>
                        <p className="has-text-black mb-2">
                          Olá,{' '}
                          <strong className="has-text-black">{name}</strong>.
                          Identificamos seu vínculo com o Governo através da{' '}
                          <strong className="has-text-black">
                            {organizations.map(
                              (unidade, key) =>
                                (key > 0 ? ', ' : '') + unidade.sigla
                            )}
                          </strong>
                          .
                        </p>
                        <p className="has-text-black mb-5">
                          Preencha o formulário abaixo para concluir seu
                          cadastro.
                        </p>
                        <Input type="hidden" name="name" required />
                        {organizations.length > 1 ? (
                          <Select
                            name="organization"
                            mb={3}
                            size="medium"
                            required
                          >
                            {organizations.map((organization, okey) => (
                              <option
                                key={okey}
                                value={JSON.stringify({
                                  id: organization.id,
                                  sigla: organization.sigla
                                })}
                              >
                                {organization.sigla}
                              </option>
                            ))}
                          </Select>
                        ) : (
                          <Input
                            type="hidden"
                            name="organization"
                            defaultValue={JSON.stringify({
                              id: organizations[0].id,
                              sigla: organizations[0].sigla
                            })}
                            required
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <h4 className="has-text-grey is-uppercase mb-2">
                          Usuário externo
                        </h4>
                        <p className="has-text-black mb-2">
                          Não encontramos nenhum vínculo ativo com o Governo de
                          Rondônia. Você terá acesso a serviços de acesso
                          público do Governo de Rondônia ao concluir seu
                          cadastro.
                        </p>
                        <p className="has-text-black mb-5">
                          Preencha o formulário abaixo para concluir seu
                          cadastro.
                        </p>
                        <Input
                          mb={3}
                          name="name"
                          placeholder="Nome"
                          size="medium"
                          icon="id-badge"
                          autoComplete="new-password"
                          required
                        />
                      </>
                    )}
                    <Input
                      mb={5}
                      name="email"
                      placeholder="E-mail"
                      type="email"
                      size="medium"
                      icon="envelope"
                      autoComplete="new-password"
                      required
                    />
                    {!organizations && (
                      <p className="has-text-black mb-5">
                        Caso seja um servidor público recém contratado, aguarde
                        até que todo o processo de sua nomeação seja concluído
                        para se cadastrar na Central do Desenvolvedor.
                      </p>
                    )}
                    <Button
                      type="submit"
                      icon="check-circle"
                      extraClass="is-link is-fullwidth is-medium"
                      small={false}
                    >
                      Concluir
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  <Form
                    onSubmit={async data => {
                      const { cpf: __cpf } = data
                      // Se um CPF não for enviado, retorna erro
                      if (!__cpf)
                        return setAlert(
                          <Alert color="danger">
                            É preciso enviar um CPF para continuar o cadastro!
                          </Alert>
                        )
                      // Se o CPF enviado for inválido, retorna falso
                      if (!CPF.validate(__cpf))
                        return setAlert(
                          <Alert color="danger">Digite um CPF válido!</Alert>
                        )
                      // Se o usuário já existir na central, redireciona para login
                      if (await isUser(CPF.strip(__cpf))) {
                        setAlert(
                          <Alert color="info">
                            Seu CPF já está cadastrado na Central do
                            Desenvolvedor, use o formulário abaixo para fazer o
                            login!
                          </Alert>
                        )
                        return setLogin(true)
                      }
                      // Se o usuário já existir no e-estado, cadastrar no Sauron
                      await isServidor(CPF.strip(__cpf))
                      // Define variável de estado para CPF
                      setCpf(CPF.strip(__cpf))
                      // Continua cadastro
                      setContinuar(true)
                    }}
                    autoComplete="new-password"
                  >
                    <h4 className="has-text-grey is-uppercase mb-2">
                      Identifique-se
                    </h4>
                    <p className="has-text-black mb-5">
                      Digite seu CPF para identificarmos seu vínculo com o
                      Governo de Rondônia.
                    </p>
                    <Input
                      mb={5}
                      name="cpf"
                      placeholder="CPF"
                      autoComplete="new-password"
                      size="medium"
                      icon="user"
                      required
                    />
                    <div className="text-center pb-4">
                      <div className="g-recaptcha">
                        <ReCAPTCHA
                            size="normal"
                            ref={recaptchaRef}
                            sitekey={recaptchaSiteKey}
                            onChange={(token) => {
                              setCaptchaToken(token)
                              setCaptchaSolved(true)
                            }}
                            onErrored={() => {
                              setCaptchaSolved(false)
                            }}
                            onExpired={() => {
                              setCaptchaSolved(false)
                            }}
                        />
                      </div>
                    </div>
                    <Button
                      type="submit"
                      icon="arrow-right"
                      extraClass="is-link is-fullwidth is-medium"
                      small={false}
                      disabled={!captchaSolved}>
                      Continuar
                    </Button>
                  </Form>
                </>
              )}
              <div className="mt-5 has-text-black has-text-centered">
                Ao se cadastrar, você estará concordando com nossa{' '}
                <Link to="/" className="has-text-weight-bold">
                  Política de Privacidade
                </Link>
                ,{' '}
                <Link to="/" className="has-text-weight-bold">
                  Termos de Uso
                </Link>{' '}
                e o uso de Cookies.
              </div>
            </div>
          </div>
        </div>
        <p className="has-text-white has-text-centered mt-4">
          <Link to="/" className="mt-3 has-text-weight-bold">
            <i className="fas fa-arrow-left mr-1"></i> Voltar
          </Link>
        </p>
      </div>
    </div>
  )
}
