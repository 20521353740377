import React, { useState, useCallback, useEffect } from 'react'

import api from '../../../services/api'
import AdicionarEndpoint from './AdicionarEndpoint'
import ApagarEndpoint from './ApagarEndpoint'

export default function ListarEndpoints({ api_id, permissions }) {
  const [endpoints, setEndpoints] = useState(false)

  const tagMethod = __method => {
    switch (__method) {
      case 'get':
        return 'success'
      case 'post':
        return 'warning'
      case 'put':
        return 'info'
      case 'patch':
        return 'info'
      case 'delete':
        return 'danger'
      case 'options':
        return 'dark'
      case 'head':
        return 'dark'
      default:
        return 'light'
    }
  }

  const loadEndpoints = useCallback(async () => {
    const r = await api.get(`/apis/${api_id}/endpoints?limit=9999`)
    setEndpoints(r.data)
  }, [api_id])

  useEffect(() => {
    loadEndpoints()
  }, [loadEndpoints])

  return (
    <>
      <AdicionarEndpoint
        api_id={api_id}
        afterCreate={loadEndpoints}
        permissions={permissions}
      />
      <table className="table is-hoverable is-fullwidth mt-3">
        <thead>
          <tr>
            <th className="has-text-centered has-text-grey-light is-narrow">
              <i className="fas fa-trash"></i>
            </th>
            <th colSpan="2">Path</th>
            <th>Permissão</th>
          </tr>
        </thead>
        <tbody>
          {!endpoints && (
            <tr>
              <td colSpan="4">
                <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
              </td>
            </tr>
          )}
          {endpoints && (
            <>
              {endpoints.length === 0 && (
                <tr>
                  <td colSpan="4">Nenhum endpoint adicionado</td>
                </tr>
              )}
              {endpoints.length > 0 && (
                <>
                  {endpoints.map((endpoint, key) => (
                    <tr key={key}>
                      <td className="is-vcentered">
                        <ApagarEndpoint
                          api_id={api_id}
                          endpoint_id={endpoint._id}
                          afterDelete={loadEndpoints}
                        />
                      </td>
                      <td className="is-vcentered is-narrow">
                        <span
                          className={`tag is-${tagMethod(endpoint.method)}`}
                        >
                          {endpoint.method}
                        </span>
                      </td>
                      <td className="is-vcentered">{endpoint.path}</td>
                      <td className="is-narrow is-vcentered">
                        {endpoint.permission}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </>
  )
}
