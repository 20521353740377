import React, { useCallback, useEffect, useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonAdd } from '../../../components/ButtonAdd'

export default function AdicionarPermissao({ key_id, api_id, afterCreate }) {
  const [alert, setAlert] = useState()
  const [permission, setPermission] = useState('')
  const [permissions, setPermissions] = useState([])

  const loadPermissions = useCallback(async () => {
    const r = await api.get(`/apis/${api_id}/permissoes`)
    setPermissions(r.data)
  }, [api_id])

  useEffect(() => {
    loadPermissions()
  }, [loadPermissions])

  async function savePermissao(e) {
    e.preventDefault()
    const button = e.target
    button.disabled = true
    const r = await api
      .post(`/chaves/${key_id}/permissoes`, {
        key_id,
        permission
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Permissão adicionada!</Alert>)
      afterCreate()
    }
    button.disabled = false
  }

  return (
    <>
      {alert}
      <div className="mb-3">
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field">
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    name="permission"
                    id="permission-key"
                    onChange={e => setPermission(JSON.parse(e.target.value))}
                  >
                    <option value="">Selecione...</option>
                    {permissions &&
                      permissions.length > 0 &&
                      permissions.map((permissao, pkey) => (
                        <option key={pkey} value={JSON.stringify(permissao)}>
                          {permissao.name}
                          {permissao.moderate ? ' - Requer moderação' : ''}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <ButtonAdd
              small={false}
              onClick={e => savePermissao(e)}
            ></ButtonAdd>
          </div>
        </div>
      </div>
    </>
  )
}
