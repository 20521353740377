import React, { useState, useCallback } from 'react'
import { Redirect } from 'react-router-dom'

import api from '../services/api'
import organograma_api from '../services/organograma_api'
import {
  hasMatriculaAtiva,
  hasLocalTrabalho,
  hasFNG,
  getUnidadeGestora
} from '../helpers/servidor.helper'
import PageTitle from '../components/PageTitle'
import Button from '../components/Button'
import Alert from '../components/Alert'
import { DetailBox } from '../components/DetailBox'

const SouServidor = function ({ session }) {
  const {
    user: { _id: user_id, public: isUserPublic }
  } = session
  const [loading, setLoading] = useState(false)
  const [verificou, setVerificou] = useState(false)
  const [matriculas, setMatriculas] = useState(false)
  const [departamentos, setDepartamentos] = useState(false)
  const [unidades, setUnidades] = useState(false)
  const [fng, setFng] = useState(false)
  const [atualizado, setAtualizado] = useState(false)

  const hasVinculo = useCallback(async () => {
    if (!user_id) return
    setLoading(true)
    setVerificou(true)

    const { data: { cpf } = {} } = await api
      .get(`/usuarios/${user_id}`)
      .then(res => res)
      .catch(err => err.response)
    if (!cpf) {
      setLoading(false)
      return
    }

    const _matriculas = await hasMatriculaAtiva(cpf)
    if (!_matriculas) {
      setLoading(false)
      return
    }
    setMatriculas(_matriculas)

    const _departamentos = hasLocalTrabalho(_matriculas)
    if (_departamentos) {
      setDepartamentos(_departamentos)

      const _unidades = await getUnidadeGestora(_departamentos)
      setUnidades(_unidades)
    }

    setFng(hasFNG(_matriculas))

    setLoading(false)
  }, [user_id])

  // Consulta nova organização do usuário
  /**
   * Define Organização do usuário
   * @param {object} unidade
   * @returns
   */
  async function setOrganization(unidade) {
    // Consulta se a unidade existe na Central
    return await api
      .get(`/organizacoes/nome/${unidade.sigla}`)
      .then(async response => {
        // Se a unidade existir, retorna seu ID
        // Se não, cria unidade e retorna seu ID
        if (response.status === 200 && response.data) {
          return response.data._id
        } else if (response.status === 200 && response.data === false) {
          return await api
            .post(`/organizacoes`, {
              name: unidade.sigla,
              organograma: await getUnidadeOrganograma(unidade.id)
            })
            .then(res => {
              return res.data._id
            })
        }
      })
  }

  // Consulta detalhes da unidade no Organograma
  async function getUnidadeOrganograma(unidade_id) {
    if (!unidade_id) return {}
    const r = await organograma_api
      .get(`/unidades/${unidade_id}/detalhes`)
      .then(res => res)
      .catch(err => err.response)
    if (r?.status === 200) {
      return r.data
    } else {
      return {}
    }
  }

  async function atualizarCadastro(e) {
    await api
      .put(`/usuarios/${user_id}`, {
        admin: false,
        public: false,
        organization: await setOrganization(unidades[0])
      })
      .then(res => setAtualizado(true))
      .catch(error => error.response.data)
  }

  if (atualizado) {
    return (
      <>
        <Alert color="success">
          Cadastro atualizado! Faça o login novamente!
        </Alert>
        <Redirect to="/logout" />
      </>
    )
  }

  return user_id && isUserPublic ? (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-id-badge mr-1"></i> Sou
            Servidor
          </>
        }
      ></PageTitle>
      <div className="box mt-5 has-background-danger-light has-text-danger">
        <i className="fas fa-exclamation-triangle mr-2"></i> Atenção! O abuso
        desta funcionalidade pode resultar em bloqueio no seu acesso a Central
        do Desenvolvedor.
      </div>
      <div className="box mt-5">
        <p className="mb-3">
          Se você é um servidor público e foi cadastrado como usuário externo,
          use o botão abaixo para verificar seu vínculo com o Governo de
          rondônia.
        </p>
        <Button
          icon="search"
          extraClass="is-link has-text-dark"
          disabled={verificou}
          onClick={hasVinculo}
        >
          Verificar vínculo
        </Button>
      </div>
      {verificou && !loading && (
        <div
          className={`box has-background-${
            matriculas ? 'link' : 'danger'
          } has-text-dark mt-5`}
        >
          <i
            className={`fas fa-${matriculas ? 'check-circle' : 'times'} mr-2`}
          ></i>{' '}
          {matriculas ? 'Encontrado!' : 'Não encontrado!'}
        </div>
      )}
      <DetailBox
        title="Vínculo com o Governo"
        icon="id-card"
        show={!loading && verificou}
      >
        {matriculas ? (
          <>
            <p className="has-text-weight-bold is-size-5 mb-3">
              {matriculas[0].pessoa.nome}
            </p>
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Matrícula</th>
                  <th>Local de Trabalho</th>
                </tr>
              </thead>
              <tbody>
                {matriculas &&
                  matriculas.map(matricula =>
                    matricula.departamentos.map(departamento => (
                      <tr key={`${matricula.id}-${departamento.id}`}>
                        <td className="is-narrow is-vcentered">
                          {matricula.matricula}
                        </td>
                        <td>
                          <span className="tag is-dark mr-2">Departamento</span>
                          {`${departamento.nome}`}
                          <div className="mt-1">
                            <span className="tag is-dark mr-2">Unidade</span>
                            {`${departamento.unidade.sigla} - ${departamento.unidade.nome}`}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
              </tbody>
            </table>
          </>
        ) : (
          <>Não encontrou</>
        )}
      </DetailBox>
      <DetailBox
        title="Pré-requisitos"
        icon="tasks"
        description="Para concluir seu cadastro como servidor e ter acesso ao escopo da sua unidade de trabalho é necessário satisfazer os requisitos abaixo:"
        show={!loading && verificou}
      >
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th className="is-vcentered is-narrow">Situação</th>
              <th>Requisito</th>
            </tr>
          </thead>
          <tbody>
            <tr className={`has-text-${matriculas ? 'link' : 'danger'}`}>
              <td className="has-text-centered">
                <i className={`fas fa-${matriculas ? 'check' : 'times'}`}></i>
              </td>
              <td>Possuir um vínculo ativo com o Governo de Rondônia</td>
            </tr>
            <tr
              className={`has-text-${
                matriculas && departamentos ? 'link' : 'danger'
              }`}
            >
              <td className="has-text-centered">
                <i
                  className={`fas fa-${
                    matriculas && departamentos ? 'check' : 'times'
                  }`}
                ></i>
              </td>
              <td>
                Possuir um local de trabalho padrão no seu cadastro de servidor
                dentro do módulo de RH do E-Estado.
              </td>
            </tr>
            <tr className={`has-text-${matriculas && fng ? 'link' : 'danger'}`}>
              <td className="has-text-centered">
                <i
                  className={`fas fa-${matriculas && fng ? 'check' : 'times'}`}
                ></i>
              </td>
              <td>
                Possuir a função não gratificada "Desenvolvedor" ou similar
                associada ao seu cadastro de servidor dentro do E-Estado.
              </td>
            </tr>
          </tbody>
        </table>
        {matriculas && departamentos && (
          <Button
            icon="sync-alt"
            extraClass="is-link has-text-dark"
            onClick={atualizarCadastro}
          >
            Atualizar meu cadastro
          </Button>
        )}
      </DetailBox>
    </>
  ) : (
    <>
      <Alert color="danger">
        Você não tem permissão para acessar esta página!
      </Alert>
      <Redirect to="/central" />
    </>
  )
}

export default SouServidor
