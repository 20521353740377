import React, { useState } from 'react'

import api from '../../../services/api'
import Alert from '../../../components/Alert'
import { ButtonDelete } from '../../../components/ButtonDelete'

export default function RemoverAdministrador({
  organization_id,
  user_id,
  afterDelete
}) {
  const [alert, setAlert] = useState()

  async function saveUsuario(e) {
    e.preventDefault()
    const button = e.target
    button.disabled = true
    const r = await api
      .put(`/usuarios/${user_id}`, {
        organization: organization_id,
        admin: false
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Administrador removido!</Alert>)
      afterDelete()
    }
    button.disabled = false
  }

  return (
    <>
      {alert}
      <ButtonDelete
        onClick={e => {
          e.preventDefault()
          if (
            window.confirm('Tem certeza que deseja remover esta administrador?')
          )
            saveUsuario(e)
        }}
        title="Remover administrador"
      />
    </>
  )
}
