import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { Form } from '@unform/web'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import { Input, Checkbox, Select, Submit } from '../../components/Formulario'
import Alert from '../../components/Alert'

export default function Update() {
  const { uid } = useParams()
  const formRef = useRef()
  const history = useHistory()
  const [data, setData] = useState(false)
  const [alert, setAlert] = useState(false)
  const [error, setError] = useState(false)
  const [organizations, setOrganizations] = useState([])

  const getOrganizations = useCallback(async () => {
    const r = await api.get(`/organizacoes/`)
    setOrganizations(r.data)
  }, [])

  const loadData = useCallback(async () => {
    const r = await api
      .get(`/usuarios/${uid}`)
      .then(data => data)
      .catch(error => error.response)
    if (r.status !== 200) {
      setError(r.data)
    } else {
      setData(r.data)
    }
  }, [uid])

  useEffect(() => {
    getOrganizations()
    loadData()
  }, [getOrganizations, loadData])

  async function saveForm(data) {
    const r = await api
      .put(`/usuarios/${uid}`, {
        ...data,
        admin: parseInt(data.admin),
        public: parseInt(data.public)
      })
      .then(data => data)
      .catch(error => error.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Usuário atualizado!</Alert>)
    }
  }

  async function deleteUsuario() {
    const r = await api.delete(`/usuarios/${uid}`)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">Usuário apagado!</Alert>)
      history.push('/central/usuarios')
    } else {
      setAlert(
        <Alert color="danger">
          Não foi possível excluir o Usuário.
          <br />
          Tente novamente ou entre em contato com o suporte da Central do
          Desenvolvedor.
        </Alert>
      )
    }
  }

  return error ? (
    <>
      <Alert color="danger">{error.error.message}</Alert>
      <Redirect to="/central/usuarios" />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="has-text-grey-light fas fa-users mr-1"></i> Usuários{' '}
            <i className="fas fa-angle-right mx-2"></i> Editar Usuário
          </>
        }
      >
        <Button
          url={`/central/usuarios/${uid}/detalhes`}
          icon="arrow-left"
          extraClass="mr-3"
        >
          Voltar
        </Button>
        <Button
          url="#delete"
          onClick={e => {
            e.preventDefault()
            if (
              window.confirm(
                `Tem certeza que deseja apagar o usuário ${data.name}?`
              )
            )
              deleteUsuario()
          }}
          icon="trash"
          extraClass="is-danger"
        >
          Apagar
        </Button>
      </PageTitle>

      <div className="box mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {alert}
        {data && (
          <Form ref={formRef} initialData={data} onSubmit={saveForm}>
            <Input name="name" label="Nome" type="text" required />
            <Input name="cpf" label="CPF" type="text" disabled />
            <Input name="email" label="E-mail" type="email" required />
            <Select
              name="organization"
              label="Organização"
              defaultValue={data.organization?._id}
            >
              <option value="">Selecione...</option>
              {organizations &&
                organizations.map((organization, okey) => (
                  <option key={okey} value={organization._id}>
                    {organization.name}
                  </option>
                ))}
            </Select>
            <Checkbox
              name="admin"
              label="Administrador"
              value={1}
              defaultChecked={data.admin ? true : false}
            />
            <Checkbox
              name="public"
              label="Usuário externo"
              value={1}
              defaultChecked={data.public ? true : false}
            />
            <Submit cancelUrl={`/central/usuarios/${uid}/detalhes`} />
          </Form>
        )}
      </div>
    </>
  )
}
