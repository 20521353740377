import React from 'react'

export function ButtonAdd({ extraClass, small = true, children, ...props }) {
  return (
    <div className="field is-narrow">
      <div className="control">
        <button
          type="button"
          className={`button ${small ? 'is-small' : ''} is-link ${extraClass}`}
          {...props}
        >
          <i className={`fas fa-plus  ${children ? 'mr-2' : ''}`}></i>
          {children && (
            <span className="is-uppercase has-text-weight-bold">
              {children}
            </span>
          )}
        </button>
      </div>
    </div>
  )
}
