import React, { useRef, useState, useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { Form } from '@unform/web'

import api from '../../services/api'
import PageTitle from '../../components/PageTitle'
import Button from '../../components/Button'
import { Input, Submit } from '../../components/Formulario'
import Alert from '../../components/Alert'

export default function Update({ session }) {
  const { id } = useParams()
  const formRef = useRef()
  const history = useHistory()
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const [alert, setAlert] = useState(false)
  const {
    user: { public: isUserPublic }
  } = session

  useEffect(() => {
    async function loadData() {
      const r = await api
        .get(`/chaves/${id}`)
        .then(data => {
          const {
            user: { _id: user_id }
          } = session
          const { users } = data.data
          if (!users.filter(user => user === user_id)) {
            return {
              error: {
                message: 'Seu usuário não tem permissão para acessar esta chave'
              }
            }
          } else {
            return data
          }
        })
        .catch(err => err.response.data)
      if (r.error) {
        setError(r.error)
      } else {
        if (!r.data.api.public && isUserPublic) {
          setError({
            message: 'Você não tem permissão para editar esta chave de API.'
          })
        } else {
          setData(r.data)
        }
      }
    }
    loadData()
  }, [id, session, isUserPublic])

  async function saveForm(formData) {
    const { disabled, ...data } = formData
    const r = await api
      .put(`/chaves/${id}`, data)
      .then(data => data)
      .catch(err => err.response.data)
    if (r.error) {
      setAlert(<Alert color="danger">{r.error.message}</Alert>)
    } else {
      setAlert(<Alert color="success">Chave atualizada!</Alert>)
    }
  }

  async function deleteChave() {
    const r = await api.delete(`/chaves/${id}`)
    if ('status' in r && r.status === 204) {
      setAlert(<Alert color="success">Chave apagada!</Alert>)
      history.push('/central/minhas-chaves')
    } else {
      setAlert(
        <Alert color="danger">
          Não foi possível excluir a Chave de API.
          <br />
          Tente novamente ou entre em contato com o suporte da Central do
          Desenvolvedor.
        </Alert>
      )
    }
  }

  return error ? (
    <>
      <Alert color="danger">{error.message}</Alert>
      <Redirect to={`/central/minhas-chaves/${id}/detalhes`} />
    </>
  ) : (
    <>
      <PageTitle
        title={
          <>
            <i className="fas fa-key mr-2"></i> Minhas Chaves{' '}
            <i className="fas fa-chevron-right mx-2"></i> Editar Chave
          </>
        }
      >
        <Button
          url={`/central/minhas-chaves/${id}/detalhes`}
          icon="arrow-left"
          extraClass="mr-3"
        >
          Voltar
        </Button>
        <Button
          url="#delete"
          onClick={e => {
            e.preventDefault()
            if (window.confirm(`Tem certeza que deseja apagar a chave?`))
              deleteChave()
          }}
          icon="trash"
          extraClass="is-danger"
        >
          Apagar
        </Button>
      </PageTitle>

      <div className="box mt-5">
        {!data && (
          <p>
            <i className="fas fa-spinner fa-pulse mr-1"></i> Carregando...
          </p>
        )}
        {alert}
        {data && (
          <Form ref={formRef} initialData={data} onSubmit={saveForm}>
            <p className="mb-4">
              <span>
                <i className="fas fa-code"></i> API
              </span>
              : <strong>{data.api.name}</strong>
              <br />
              <span className="tag is-dark mt-2">
                Ambiente de {data.api.test_environment ? 'Testes' : 'Produção'}
              </span>
            </p>
            <Input
              name="reason"
              label="Objetivo"
              hint="Explique seu objetivo com o uso desta chave. Muito importante para moderação de permissões."
              type="text"
              required
            />
            <Submit cancelUrl={`/central/minhas-chaves/${id}/detalhes`} />
          </Form>
        )}
      </div>
    </>
  )
}
