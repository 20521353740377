import api from './services/api'
import jwt from 'jsonwebtoken'

const getSession = () => {
  // Consulta token no localStorage
  const token = localStorage.getItem('cdev_session')
  // Se não encontrar o token, retorna falso
  if (!token) return false
  const data = jwt.decode(token)
  // Valida se o token precisa ser renovado
  timeToRenew(token, data)
  // Retorna token decodificado
  return data
}

const timeToRenew = (token, data) => {
  if (!data) return false
  const now = new Date()
  const expiresIn = new Date(data.exp * 1000)
  // Se faltar menos de 2 minutos para a sessão expirar, renova
  if (now.getTime() >= expiresIn.getTime() - 120 * 1000)
    renewToken(data.id, token)
  return data
}

const renewToken = async (id, token) => {
  return await api
    .post(`/sessoes/${id}`, { token })
    .then(res => {
      localStorage.setItem('cdev_session', res.data.token)
    })
    .catch(err => {
      localStorage.clear()
    })
  // Se receber um novo token, atualiza localStorage e retorna dados da sessão
  // Se não, retorna falso
}

export const isLoggedIn = () => {
  // Consulta sessão armazenada em localStorage
  const session = getSession()
  // Se o usuários estiver logado retorna seus dados, se não retorna falso
  return session
}
